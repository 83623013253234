import { Component, OnDestroy, OnChanges, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import {ReusableService} from '../reusable.service';


import * as React from 'react';

import * as ReactDOM from 'react-dom';

import BreezeBook from './react-modules/reactComponent';

@Component({
  selector: 'app-breezebook',
  template: '<div [id]="rootId"></div>',
  styleUrls: ['./react-modules/breezebook.css','./react-modules/appointmentComponent/appointment.css','./react-modules/loader/loader.css','./react-modules/ThemeSelector/themeSelector.css'],
  encapsulation: ViewEncapsulation.None
})

export class BreezeBookComponent implements OnDestroy, OnChanges, AfterViewInit{

    @Input() active : boolean;
    @Input() role : string;
    public rootId = 'bmw_BreezeBook';
    private hasViewLoaded = false;

    constructor(public reuse:ReusableService) { }

    private renderComponent(){
      if (!this.hasViewLoaded) {
        return;
      }
      ReactDOM.render(<BreezeBook role={this.role} active={this.active} />,document.getElementById(this.rootId));
  }
    
    ngOnChanges() {
      this.renderComponent();
    }

    ngAfterViewInit() {
      this.renderComponent();
    }

    ngDoCheck(){
      if(document.getElementById(this.rootId) && !this.hasViewLoaded){
        this.hasViewLoaded = true;
        this.renderComponent();
      }
    }

    ngOnDestroy() {
      document.getElementById(this.rootId) && ReactDOM.unmountComponentAtNode(document.getElementById(this.rootId));
    }
}