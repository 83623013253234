import React from 'react'
import { CSVLink } from 'react-csv'

const ExportToCSV = ({csvData, fileName}) => {
    const headers = [
        {label: "Name", key: "name"},
        {label: "Number", key: "number"},
        {label: "E-mail", key: "email"},
        {label: "Appointment Type", key: "service_name"},
        {label: "Date(M/D/Y)", key: "date"},
        {label: "Start Time", key: "start_time"},
        {label: "End Time", key: "end_time"},
        {label: "Duration(mins)", key: "duration_min"},
        {label: "Status", key: "status"},
        {label: "Staff", key: "staff_name"},
        {label: "Paid (CAD)", key: "paid_amount"},
        {label: "Refunded (CAD)", key: "refund_amount"}
    ];
    return (      
            <CSVLink data={csvData} filename={fileName} headers={headers} separator={","}>
                <button style={{background:'darkslategray',textTransform:'none',color:'white',outline:'none'}} className='btn' >
                    <i style={{marginRight:'4px'}} className='material-icons left'>file_download</i>
                    Export
                </button>
            </CSVLink>
    )
}

export default ExportToCSV;