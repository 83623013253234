import React from 'react'

import ScheduleDay from './scheduleDay'

const Schedule = (props) => {

    const days=['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];

    const dateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }    

    const getDate = (offset) => {
        let MonDate = new Date(props.weekDate);
        return new Date(MonDate.setDate(MonDate.getDate() + offset)).toLocaleDateString('en-US',dateOptions);
    }

    return(
        <li onClick={props.setActive} className={'Schedule ' + (props.active ? 'active' : '')}>
            <div className="collapsible-header">{'Week - ' + props.weekDate}<i className="material-icons">unfold_more</i></div>
            <div className="collapsible-body">
                <h6>Available Hours</h6>
                {
                    days.map((day,index) => (
                        <ScheduleDay 
                        key={day} 
                        day={day} 
                        date={getDate(index)} 
                        slots={props.data[day]}
                        actionEnabled={props.isAdmin}
                        addClick={()=>props.addAvail(getDate(index),day,props.weekDate)}
                        closeClick={props.deleteAvail} />
                    ))
                }
            </div>
        </li>
    )
}

export default Schedule;