import React from 'react'

import {cacheHeaders,basePath} from '../config'
import M from 'materialize-css';
import ServiceStaff from './serviceStaff'
import Loader from '../loader/loader'

interface compState {
    [key: string]: any
}

class ServiceStaffComponent extends React.PureComponent<{},compState>{
    state={
        isLoading:true,
        selectedStaff:0,
        staffList: [],
        availableServices: [],
        remainingServices: [],
        assignedServices: []
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    selectChangeHandler = (ev) => {
        this.getAssignedServices(ev.target.value);
    }

    getAssignedServices = (staff_id) => {
        this.setState({isLoading: true});
        fetch(basePath+'staffService?location_id='+localStorage.location_id+'&staff_id='+staff_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                let assignedServices = [...result.data];
                let availableServices = [...this.state.availableServices]
                let remainingServices = availableServices.filter(availService => {
                    for(let service of assignedServices){
                        if(service.service_id === availService.id){
                            return false;
                        }
                    }
                    return true;
                });
                this.setState({remainingServices: remainingServices,assignedServices: assignedServices,selectedStaff: staff_id});
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        }) 
    }

    addServiceToStaff = (id) => {
        this.setState({isLoading: true});
        let postData ={
            service_id : id,
            staff_id : this.state.selectedStaff
        }
        fetch(basePath+'staffService',{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            this.toaster(result.success, result.message);
            if(result.success){
                this.getAssignedServices(this.state.selectedStaff);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    removeServiceFromStaff = (id) => {
        this.setState({isLoading: true});
        fetch(basePath+'staffService/'+id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'delete'
        })
        .then(res => res.ok ? res.json() : Promise.reject())
        .then(result => {
            this.toaster(result.success,result.message);
            result.success && this.getAssignedServices(this.state.selectedStaff);
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    componentDidMount(){
        M.AutoInit();
        // Get all the available Staff
        this.setState({isLoading: true});
        fetch(basePath+'staffLocation?location_id='+localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                if(result.data.length !==0){
                    this.setState({staffList: result.data})
                }
                let elems = document.querySelectorAll('#staff_select');
                M.FormSelect.init(elems);
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        });

        // Get all the available services
        fetch(basePath+'service?location_id='+ localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({availableServices: result.data});
            }else{
                this.toaster(false, result.message);
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
        })

    }

    render(){
        let options = this.state.staffList.map(staff => 
            (<option key={staff.id} value={staff.staff_id}>
                {staff.first_name+' '+staff.last_name}
            </option>)
        )
        return (
            <>
                <div className='ActionDiv'>
                    <h4>Staff - Service Mapping</h4>
                </div>
                <div className='InfoTip'>
                    <i style={{color:'#4CAF50'}} className={"material-icons"}>info</i>
                    <span>Pick a Staff and Click on a Service to assign or remove it</span>
                </div> 
                <div className="input-field col s12 StaffSelect">
                    <select defaultValue='0' id="staff_select" onChange={this.selectChangeHandler}>
                        <option value='0' disabled>Choose a staff from the list</option>
                        {options}
                    </select>
                    <label style={{fontSize:'16px',color:'#20232a'}}>Pick a Staff</label>
                </div>           
                {this.state.selectedStaff !== 0 &&
                <div className='Mapper'>
                    <div className='LeftColumn'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>Available Services</h5>
                        {
                            this.state.remainingServices.length === 0 ? <h6>Services Not Available</h6> :
                            this.state.remainingServices.map(service => (
                                <ServiceStaff key={service.id} clicked={() => this.addServiceToStaff(service.id)} name={service.name} dir='left' />
                            ))
                        }
                    </div>
                    <div className='RightColumn'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>Services Assigned</h5>
                        {
                            this.state.assignedServices.length === 0 ? <h6>Services Not Available</h6> :
                            this.state.assignedServices.map(service => (
                                <ServiceStaff key={service.id} clicked={() => this.removeServiceFromStaff(service.id)} name={service.name} dir='right' />
                            ))
                        }
                    </div>
                </div> 
                } 
                {this.state.isLoading && <Loader />}            
            </>
        )
    }
}

export default ServiceStaffComponent;