<div class="card">
  <div class="card-header"></div>

  <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field class="inputelements">
        <input
          matInput
          placeholder="Template Name"
          value=""
          formControlName="name"
        />
      </mat-form-field>

      <br />
      <mat-form-field class="inputelements">
        <textarea
          matInput
          name="textdata"
          placeholder="Template Data"
          formControlName="content"
        ></textarea>
      </mat-form-field>

      <mat-card>
        Select the type of content you want to send out:
        <mat-radio-group
          aria-label="Select an option"
          style="display: flex;justify-content: space-around;margin: 10px;"
          formControlName="link_id"
        >
          <mat-radio-button value="2">Filter URL</mat-radio-button>
          <mat-radio-button value="1">Direct URL</mat-radio-button>
          <mat-radio-button value="3">No URL</mat-radio-button>
          <br />
        </mat-radio-group>
      </mat-card>
      <br />

      <mat-card>
        Select the Social Media URL:
        <mat-radio-group
          aria-label="Select an option"
          style="display: flex;justify-content: space-around;margin: 10px;"
          formControlName="social_id"
        >
        <div *ngFor="let social of SocialArray">
          <mat-radio-button value="{{social.id}}">{{social.name}}</mat-radio-button>
        </div>
         
         
          <br />
        </mat-radio-group>
      </mat-card>
      <br />

      <div class="inputelements">
        <input type="file" name="image" (change)="onFileSelected($event)" />
      </div>

      <div class="inputelements btndiv">
        <button type="submit" class="btn btnadd" [disabled]="!reviewForm.valid">Add</button>
      </div>
    </div>
  </form>

  <mat-card>
    <mat-toolbar>
      <span>Review Templates</span>
    </mat-toolbar>
    <mat-list *ngFor="let reviewtemp of parentdata">
      <mat-list-item>
        <div class="row col-md-6 justify-content-center tabletemp">
          <div class="col-md-8">
            {{ reviewtemp.name }}
          </div>
          <div class="col-md-4 p5" >
           <!-- <mat-icon *ngIf="reviewtemp.name!=='GoogleReview'"
              aria-hidden="false"
              aria-label="Example remove icon"
              (click)="delete(reviewtemp)"
              
              style="font-size:20px;color:darkcyan;cursor: pointer;margin-right: 20%;"
              >delete</mat-icon
            > -->
            <mat-icon *ngIf="!rnames.includes(reviewtemp.name)"
            aria-hidden="false"
            aria-label="Example remove icon"
            (click)="delete(reviewtemp)"
            
            style="font-size:20px;color:darkcyan;cursor: pointer;margin-right: 20%;"
            >delete</mat-icon
          >
            <mat-icon 
              aria-hidden="false"
              aria-label="Example edit icon"
              (click)="openDialog(reviewtemp)"
              style="font-size:20px;color:darkcyan;cursor: pointer;"
              >edit</mat-icon
            > 
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
