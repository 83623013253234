import { Component, OnInit,Input } from '@angular/core';
import { CustomValidators } from "ngx-custom-validators";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  ForgetForm: FormGroup;
  submitted = false;
  email = '';

  constructor() {  
  }


  Inivalidators(){
    this.ForgetForm = new FormGroup({
      email: new FormControl("", [
        CustomValidators.email,
        Validators.compose([Validators.required])
      ]),
    });
  }

  login() {
    // console.log("called");
    this.submitted = true;

    // stop here if form is invalid
    if (this.ForgetForm.invalid) {
      return;
    }

    // display form values on success
    alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.ForgetForm.value, null, 4));
  }

  ngOnInit() {
    this.Inivalidators();
  }
}
