import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material-module/material-module'; // this is for the material Module
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ForgotComponent } from './forgot/forgot.component';
import {LoginRoutingModule,} from './login-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { LeavereviewComponent } from './leavereview/leavereview.component';
import { BusinessReviewComponent } from './businessReview/businessReview.component';
import { AlertpageComponent } from './alertpage/alertpage.component';
import { BackreviewComponent } from './backreview/backreview.component';

@NgModule({
  declarations: [LoginComponent, ForgotComponent, LeavereviewComponent, AlertpageComponent, BackreviewComponent, BusinessReviewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  entryComponents: [LoginComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModuleModule { }
