import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoginServiceService } from './login-service.service';
import {AppServiceService} from '../app-service.service'; // this.apiservice.APIEndPoint  this.apiservice.httpHeaderOptions

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService implements CanActivate {

  location_id = localStorage.location_id;
  constructor(public router: Router, public data: LoginServiceService, public apiservice : AppServiceService,private http: HttpClient,) {
    this.apiservice.httpHeaderOptions
   }

  canActivate(): boolean {
       if (!this.apiservice.isAuthenticated()) {
         this.router.navigate(['login']);
         return false;
       }
    return true;
    // }
  }

  

  login(LoginData): Observable<any> {
    return this.http.post<any>(
      this.apiservice.APIEndPoint + "login",LoginData,
      this.apiservice.httpHeaderOptions
    );
  }

  getLanding(hash): Observable<any> {
    return this.http.get<any>(this.apiservice.APIEndPoint + 'landingReview?hash='+hash, this.apiservice.httpHeaderOptions);
  }

  getBusinessReviewLanding(locationId,socialId): Observable<any>{
    return this.http.get<any>(this.apiservice.APIEndPoint + 'businessReview?location_id='+locationId+'&social_id='+socialId, this.apiservice.httpHeaderOptions);
  }

  postBusinessReview(data): Observable<any> {
    return this.http.post<any>(this.apiservice.APIEndPoint + 'businessReview', data, this.apiservice.httpHeaderOptions);
  }


  getalertLanding(hash): Observable<any> {
    return this.http.get<any>(this.apiservice.APIEndPoint + 'landingAlert?hash='+hash, this.apiservice.httpHeaderOptions);
  }

  postLanding(number,id): Observable<any> {
    return this.http.put<any>(this.apiservice.APIEndPoint + 'review/'+id+'?rate='+number, this.apiservice.httpHeaderOptions);
  }

  negativeReputation(form): Observable<any> {
    return this.http.post<any>(this.apiservice.APIEndPoint + 'negative_review',form, this.apiservice.httpHeaderOptions);
  }

  putalert(id,message,reason): Observable<any> {
    return this.http.put<any>(this.apiservice.APIEndPoint + 'reminder/'+id+'?message='+message+'&reminder_seen_id='+reason, this.apiservice.httpHeaderOptions);
  }


  getLandingBack(hash): Observable<any> {
    return this.http.get<any>(this.apiservice.APIEndPoint + 'landingBack?hash='+hash, this.apiservice.httpHeaderOptions);
  }

  postLandingback(number,id): Observable<any> {
    return this.http.put<any>(this.apiservice.APIEndPoint + 'back/'+id+'?rate='+number, this.apiservice.httpHeaderOptions);
  }

  negativeReputationback(form): Observable<any> {
    return this.http.post<any>(this.apiservice.APIEndPoint + 'negative_back',form, this.apiservice.httpHeaderOptions);
  }

}