import React,{useReducer,useRef} from 'react'

const Appointment = (props) => {

    const [formOpen, setFormOpen] = useReducer((formOpen)=>!formOpen, false);
    const refundElem = useRef(null);

    const refund = (ev) => {
        ev.preventDefault();
        props.refundFn(props.id,refundElem.current.value,props.refresh);
        // props.refresh();
        setFormOpen();
    }

    let dateArr = new Date(props.date).toDateString().split(' ');
    return (
        <div className='Appointment'>
            <div className='DateColumn'>
                <span>{dateArr[0]}</span>
                <span>{dateArr[1]+' '+dateArr[2]+', '+dateArr[3]}</span>
            </div>
            <div className='ClientInfo'>
                <div className='InfoEntry'><i className="material-icons">person</i><span>{props.name}</span></div>
                <div className='InfoEntry'><i className="material-icons">phone</i><span>{props.number}</span></div>
                <div className='InfoEntry'><i className="material-icons">mail</i><span>{props.email}</span></div>
            </div>
            <div className='ServiceInfo'>
                <div className='InfoEntry'><i className="material-icons">access_time</i><span>{props.slot}</span></div>
                <div className='InfoEntry'><i className="material-icons">settings</i><span>{props.service}</span></div>
                <div className='InfoEntry'><i className="material-icons">perm_contact_calendar</i><span>{props.staff}</span></div>
            </div>
            {
                props.paypal && <div className='PaymentInfo'>
                    <div className='InfoEntry'><i className="material-icons">attach_money</i><span>{'Paid    : '+ (props.paid ? props.paid+' '+ props.currency : '0.00') }</span></div>
                    <div className='InfoEntry'><i className="material-icons">money_off</i><span>{'Refund: '+ (props.refund ? props.refund+' '+ props.currency : '0.00')}</span></div>
                </div>
            }
            <div className='ActionColumn' style={{width: props.isAdmin && props.paypal ? '100%' : 'fit-content'}}>
                {props.actionRequired &&
                    <>
                    <button style={{color:'#4CAF50'}} onClick={props.doneFn} className='btn ActionBtn'>{props.doneName}</button>
                    <button style={{color:'#F44336'}} onClick={props.cancelFn} className='btn ActionBtn'>{props.cancelName}</button>
                    </>
                }
                {props.paypal && props.isAdmin && <button disabled={!props.paid || props.paid === props.refund} style={{color:'var(--secondary-font-color)'}} onClick={setFormOpen} className='btn ActionBtn'>{'Refund'}</button>}
                {props.paid && formOpen && <div className='RefundContainer'>
                    <form onSubmit={refund}>
                        <div className="input-field col s6">
                            <input id={"refundValue"+props.id} ref={refundElem} name='refundValue' defaultValue={(props.paid - props.refund).toFixed(2)} step="any" type="number" min='0' max={props.paid - props.refund} required autoComplete='off' className="validate" />
                            <label className='LabelActive' htmlFor={"refundValue"+props.id}>{'Refund Amount ('+props.currency+')'}</label>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-around'}}>
                            <button style={{backgroundColor:'#FFF'}} type='submit' className='btn-flat RefundBtn'><i style={{color:'#4CAF50',fontSize:'25px'}} className="material-icons">check</i></button>
                            <button style={{backgroundColor:'#FFF'}} type='button' onClick={setFormOpen} className='btn-flat RefundBtn'><i style={{color:'#F44336',fontSize:'25px'}} className="material-icons">close</i></button>
                        </div>
                    </form>
                    <div className='PointerDiv'></div>
                </div>}
            </div>
        </div>
    )
}

export default Appointment;