import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReusableService } from 'src/modules/reusable.service';
import { ReviewServiceService } from '../../review-service.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-reputation',
  templateUrl: './reputation.component.html',
  styleUrls: ['./reputation.component.css']
})

export class ReputationComponent implements OnInit {
  
  replydata:any[]=[{ reply:''}
   
  ]
  ELEMENT_DATA1: DialogData[] = [];
  ELEMENT_DATA2: DialogData[] = [];
  displayedColumns: string[] = ['id', 'first_name', 'number','email', 'message','rate','view_date','action'];
  dataSource = new MatTableDataSource<DialogData>(this.ELEMENT_DATA1);
  dataSource2 = new MatTableDataSource<DialogData>(this.ELEMENT_DATA2);

  @ViewChild('paginator1', {static: true}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: true}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort1: MatSort;
  @ViewChild(MatSort, {static: true}) sort2: MatSort;
  background = '';
  colors = '';

  pipe: DatePipe;
  
  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
});
  
get fromDate() { return this.filterForm.get('fromDate').value; }
get toDate() { return this.filterForm.get('toDate').value; }

  
  constructor(public dialog: MatDialog,public reusable: ReusableService,private data: ReviewServiceService,) { 
    this.pipe = new DatePipe('en');

    this.dataSource.filterPredicate = (data, filter) =>{
      console.log(this.fromDate);
      if (this.fromDate && this.toDate) {
        //return data.sent_date >= this.fromDate && data.sent_date <= this.toDate;
      }
      return true;
    }
  }

  ngOnInit() {
    this.background = 'lightskyblue';
    this.colors = 'white';
    this.getTemplateData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  applyDateFilter() {
    this.dataSource.filter = ''+Math.random();
    this.dataSource2.filter = ''+Math.random();
  }


    openDialog(popdata): void {
      const dialogRef = this.dialog.open(reply, {
        width: '50%',
        data: {id:popdata.id,first_name: popdata.first_name,number:popdata.number,email:popdata.email,message:popdata.message,status:popdata.status,reply:popdata.reply}
      });
      const fd = new FormData();
      dialogRef.afterClosed().subscribe(result => {
        if(result.reply && result.reply.length > 10){
          fd.append("reply", result.reply);
          fd.append("_method", 'put');
          this.reusable.showspinner();
          this.data.replyreputation(result.id,fd).subscribe(
            result => {
              // console.log(result);
              if (result.success == true) {  
                this.getTemplateData();
                this.reusable.hidespinner();
              } else {
                this.reusable.hidespinner();
                this.reusable.openSnackBar(result.error.message, "Error", "red");
              }
            },
            err => {
              this.reusable.hidespinner();
              this.reusable.openSnackBar(
                "  Cannot connect to the Portal. Please log out and log back in",
                "Error",
                "red"
              );
            }
          );
        }else{
          this.reusable.openSnackBar(
            "Reply should be more than 10 characters!",
            "Error",
            "red"
          );
        }
      });
    }

    getTemplateData() {
      this.reusable.showspinner();
  
      this.data.getreputationlist(localStorage.location_id).subscribe(
        result => {
          // console.log(result);
          if (result.success == true) {

            this.ELEMENT_DATA1 = result.data.filter(
              book => book.status == '0');
              
            this.dataSource = new MatTableDataSource<DialogData>(this.ELEMENT_DATA1);
            this.dataSource.paginator = this.paginator1;
            this.dataSource.sort = this.sort1;

            this.ELEMENT_DATA2 = result.data.filter(
              book => book.status == '1');

            this.dataSource2 = new MatTableDataSource<DialogData>(this.ELEMENT_DATA2);
            this.dataSource2.paginator = this.paginator2;
            this.dataSource2.sort = this.sort2;

            this.reusable.hidespinner();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.error.message, "Error", "red");
          }
        },
        err => {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(
            "  Cannot connect to the Portal. Please log out and log back in",
            "Error",
            "red"
          );
        }
      );
    }


    
}

export interface DialogData {
  first_name: string;
  id: number;
  number: string;
  email:string;
  message: string;
  rate:string;
  status:string;
  view_date:string;
  reply:string;
}

@Component({
  selector: 'reply-reputation',
  templateUrl: './popupGuest.html',
})


export class reply {

  constructor(
    public dialogRef: MatDialogRef<reply>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  

}