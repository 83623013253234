<app-header></app-header>
<div class="home-container">
  <div class="containerForTabsAndContent">
    <!-- Tab selection here -->
    <!-- <div class="tabs"></div> -->
    <mat-tab-group [selectedIndex]="0">
      <mat-tab label="Request" *ngIf="reuse.data.alert == '1' || reuse.data.review == '1'"  [selectedIndex]="1">
        <app-request></app-request>
      </mat-tab>
      <mat-tab label="Review" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager') && reuse.data.review">
          <div class="">
        <app-overview [active]="reuse.data.review == '1'" [dashboard]="'review'"></app-overview>
          </div>
      </mat-tab>
      <mat-tab label="Back" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager') && (reuse.data.back || reuse.data.coupon || reuse.data.vip) ">
        <app-view [active]="reuse.data.back == '1' || reuse.data.coupon == '1' || reuse.data.vip == '1'" [type]="'Back'"></app-view>
      </mat-tab>
      <mat-tab label="Alert" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager') && reuse.data.alert">
        <div class="">
      <app-overview [active]="reuse.data.alert == '1'" [dashboard]="'alert'"></app-overview>
        </div>
      </mat-tab>    
      <mat-tab label="Data" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager') && (reuse.data.alert == '1' || reuse.data.review == '1') ">
        <app-view [type]="'Data'"></app-view>
      </mat-tab>
      <mat-tab label="Book" *ngIf="(reuse.data.role === 'Admin' || (reuse.data.role === 'Staff' && reuse.data.book == '1')) && reuse.data.book">
        <app-breezebook [role]="reuse.data.role" [active]="reuse.data.book == '1'" ></app-breezebook>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
