import { Component, OnInit, Inject, Input } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ExtraServiceService } from "../../../extra-service.service";
import { ReusableService } from "../../../../reusable.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

export interface DialogData {
  name: string;
  id: string;
  textdata: string;
  imgsrc: string;
  template_id: string;
  _method: string;
  selectedFile: File;
  content:string;
  image:string;
}



@Component({
  selector: 'app-back-temp',
  templateUrl: './back-temp.component.html',
  styleUrls: ['./back-temp.component.css']
})
export class BackTempComponent implements OnInit {

  @Input() notifier;
  parentdata;
  selectedFile: any = null;
  image: File = null;
  reviewForm: FormGroup;
  formData: any = new FormData();

  constructor(
    public dialog: MatDialog,
    private data: ExtraServiceService,
    public reusable: ReusableService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
    this.reviewForm = this.fb.group({
      name: [""],
      content: [""],
      link_id: [""],
      category_id: [""],
      location_id: [""],
      avatar: [""]
    });
    this.getfromparent();
  }

  ngOnChanges() {
    this.getfromparent();
  }

  getfromparent() {
    this.parentdata = this.notifier;
  }



  openDialog(popdata): void {
    // console.log(popdata);
    const dialogRef = this.dialog.open(popdialogBack, {
      width: "50%",
      data: {
        name: popdata.name,
        id: popdata.id,
        content: popdata.content,
        image: popdata.image,
        template_link_id: popdata.template_link_id,
        _method: "put",
        selectedFile: popdata.selectedFile
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // console.log(result);

      const Formupdate = new FormData();
      if (result.selectedFile != undefined) {
        Formupdate.append("avatar", result.selectedFile);
      }

      Formupdate.append("id", result.id);
      Formupdate.append("name", result.name);
      Formupdate.append("content", result.content);
      Formupdate.append("link_id", '3');
      Formupdate.append("_method", "put");
      //console.log(Formupdate)
      this.data.EditTemplates(Formupdate, result.id).subscribe(
        result => {
          if (result.success == true) {
            // console.log(result);
            this.gettemplates();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.error.message, "Error", "red");
          }
        },
        err => {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(
            "  Cannot connect to the Portal. Please log out and log back in",
            "Error",
            "red"
          );
        }
      );
    });
  }

  delete(data) {
    // console.log(data);
    this.data.DeleteTemplates(data.id).subscribe(
      result => {
        if (result.success == true) {
          // console.log(result);
          this.gettemplates();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.reviewForm.get("avatar").setValue(this.selectedFile);
  }

  onSubmit() {
    const fd = new FormData();
    fd.append("avatar", this.reviewForm.get("avatar").value);
    fd.append("name", this.reviewForm.get("name").value);
    fd.append("content", this.reviewForm.get("content").value);
    fd.append("category_id", "3");
    fd.append("link_id", '3');
    fd.append("location_id", localStorage.location_id);

    this.data.createtemplate(fd).subscribe(
      result => {
        // console.log(result);
        if (result.success == true) {
          this.gettemplates();
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  gettemplates() {
    this.reusable.showspinner();
    this.parentdata = [];
    this.data.gettemplates(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {
          for (let data of result.data) {
            if (data.account_id == 3) {
              this.parentdata.push(data);
            }
          }
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }
}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../popupGuest.html',
})


export class popdialogBack {

  constructor(
    public dialogRef: MatDialogRef<popdialogBack>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  onFileSelected(event) {
  }

}
