import React from 'react'
import {cacheHeaders,basePath} from '../config'
import XLSX from 'xlsx'

import Schedule from './schedule'
import Loader from '../loader/loader'

interface propsCompState {
    [key: string]: any
}

interface schedule {
    id: number,
    date: string,
    day: string,
    weekdate: string,
    start_time: string,
    end_time: string,
    frequency: number,
    staff_id: number,
    location_title: string
}

interface scheduleArr {
    [key: number]: any
}

const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
}

const getStartDate = (currDate) => {
    let currDay = currDate.getDay() || 7;
    return new Date(currDate.setDate(currDate.getDate() - (currDay-1))).toLocaleDateString('en-US',dateOptions);
}

const getEndDate = (currDate) => {
    let currDay = currDate.getDay() || 7;
    return new Date(currDate.setDate(currDate.getDate() + (28 - currDay))).toLocaleDateString('en-US',dateOptions);
}

const days = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']

const weekObj = {"Mon": false,"Tue": false,"Wed": false,"Thu": false,"Fri": false,"Sat": false,"Sun": false}

class ScheduleComponent extends React.Component<propsCompState,propsCompState>{

    state={
        isLoading: true,
        selectedStaff:0,
        staffList: [],
        schedules: [],
        scheduleData: {},
        activeElement: 0,
        start_date : getStartDate(new Date()),
        formOpen: false,
        // Form Data for adding availabilities
        date: new Date().toLocaleDateString('en-US',dateOptions),
        day: new Date().toDateString().split(' ')[0],
        weekdate: getStartDate(new Date()),
        start_time: "09.00 AM",
        end_time: "05.00 PM",
        frequency: 30,
        weekDays: weekObj,
        scheduleFile: null
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    matInitializer = () => {
        let dateElem = document.querySelectorAll('.datepicker');
        M.Datepicker.init(dateElem, {onSelect: this.dateSelectHandler,defaultDate: new Date(this.state.start_date),setDefaultDate:true,autoClose:true});
    }

    matTimeInitializer = () => {
        let timeElems = document.querySelectorAll('.timepicker');
        M.Timepicker.init(timeElems);
    }

    formOpenHandler = () => {
        this.setState((prevState) => {
            return {formOpen : !prevState.formOpen,start_time:"09.00 AM",end_time: "05.00 PM",frequency: 30, weekDays: weekObj}
        });
        this.matTimeInitializer();
        // (document.getElementById('ScheduleForm') as HTMLFormElement).reset();
    }

    setActiveHandler = (index) => {
        this.setState({activeElement: index});
    }

    inputChangeHandler = (event) => {
        if(event.target.type === 'checkbox'){
            let weekDays = {...this.state.weekDays};
            weekDays[event.target.name] = event.target.checked;
            this.setState({weekDays:weekDays});
        }else{
            this.setState({
                [event.target.name] : event.target.value
            })
        }
    }

    addAvailabilityHandler = (date,day,weekDate) => {
        let weekDays = {...this.state.weekDays};
        weekDays[day] = true;
        this.setState({
            date: date,
            day: day,
            weekdate: weekDate,
            formOpen: true,
            weekDays: weekDays
        });
    }

    deleteAvailabilityHandler = (id) => {
        this.setState({isLoading: true});
        fetch(basePath + 'schedule/'+id,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'delete',
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            this.toaster(result.success, result.message);
            if(result.success){
                this.getSchedules(this.state.selectedStaff);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getDate = (day) => {
        let MonDate = new Date(this.state.weekdate);
        return new Date(MonDate.setDate(MonDate.getDate() + days.indexOf(day))).toLocaleDateString('en-US',dateOptions);
    }

    getWeekDate = (d) => {
        return new Date(d.setDate(d.getDate()- ((d.getDay()||7) - 1))).toLocaleDateString('en-US',dateOptions);
    }

    checkTimeRange = (start,end) => {
        let startM = start.split(' ')[1];
        let startTime = Number(start.split(' ')[0].split(':').join(''));
        let endM = end.split(' ')[1];
        let endTime = Number(end.split(' ')[0].split(':').join(''));
        if(startM === endM){
            startTime = startTime < 1200 ? startTime+1200 : startTime;
            endTime = endTime < 1200 ? endTime+1200 : endTime;
            return startTime < endTime
        }else{
            return startM < endM
        }
    }

    formSubmitHandler = (ev) => {
        ev.preventDefault();
        if(this.checkTimeRange(this.state.start_time,this.state.end_time)){
            this.setState({isLoading: true});
            let Schedules =[];
            Object.keys(this.state.weekDays).forEach((day) => {
                if(this.state.weekDays[day])
                Schedules.push({
                    date: this.getDate(day),
                    day: day,
                    weekdate: this.state.weekdate,
                    start_time: this.state.start_time,
                    end_time: this.state.end_time,
                    frequency: this.state.frequency,
                    staff_id: this.state.selectedStaff,
                    location_id: localStorage.location_id
                });
            });
            this.createSchedules(Schedules, false);
        }else{
            this.toaster(false, "End Time should be higher than Start time");
        }
    }

    createSchedules = (schedules, fromCSV) => {
        let fileInputElem = (document.getElementById('scheduleFile')) as HTMLInputElement;
        let fileNameElem = (document.querySelector('.file-path.validate')) as HTMLInputElement;
        this.setState({isLoading: true});
        let postData = {
            schedule: schedules
        }
        fetch(basePath + 'schedule',{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                if(result.overlap){
                    this.toaster(false, "An Overlap is detected in the availability time range");
                }else{
                    this.toaster(true, result.message);
                }
                !fromCSV && this.formOpenHandler();
                this.getSchedules(this.state.selectedStaff);
            }else{
                this.toaster(result.success, result.message);
            }
            this.setState({isLoading: false, scheduleFile: null});
            fileInputElem.value = null;
            fileNameElem.value = null;
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false, scheduleFile: null});
            fileInputElem.value = null;
            fileNameElem.value = null;
        })
    }

    getSchedules = (id) => {
        this.setState({schedules:[],scheduleData:{},isLoading: true})
        let url = basePath+'schedule?location_id='+localStorage.location_id+'&staff_id='+id+'&start_date='+getStartDate(new Date(this.state.start_date))+'&end_date='+getEndDate(new Date(this.state.start_date));
        fetch(url,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                if(result.data.length !==0){
                    let scheduleData: scheduleArr={};
                    let schedule: schedule;
                    // for(schedule of this.state.schedules){
                    for(schedule of result.data){
                        if(! scheduleData[schedule.weekdate]){
                            scheduleData[schedule.weekdate] = {};
                        }
                        if(! scheduleData[schedule.weekdate][schedule.day]){
                            scheduleData[schedule.weekdate][schedule.day] = [];
                        }     
                        scheduleData[schedule.weekdate][schedule.day].push(schedule);
                    }
                    this.setState({
                        scheduleData: scheduleData, 
                        schedules: result.data
                    });
                    let collElems = document.querySelectorAll('.collapsible');
                    M.Collapsible.init(collElems);
                    this.matTimeInitializer();
                }
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        });
    }
    
    selectChangeHandler = (ev) => {
        this.setState({selectedStaff: ev.target.value})
        this.getSchedules(ev.target.value);
        this.matInitializer();
    }

    dateSelectHandler = (val) => {
        this.setState({start_date: new Date(val).toLocaleDateString('en-US',dateOptions)});
        this.getSchedules(this.state.selectedStaff);
    }

    addScheduleHandler = () => {
        this.setState({isLoading: true});
        fetch(basePath+'staffScheduleWeekid?location_id='+localStorage.location_id+'&staff_id='+this.state.selectedStaff,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                let weekdate = result.data.weekdate;
                let scheduleData = {...this.state.scheduleData};
                if(!scheduleData[weekdate]){
                    scheduleData[weekdate] = {};
                    if(document.querySelector('.Schedule.active > .collapsible-header')){
                        (document.querySelector('.Schedule.active > .collapsible-header') as HTMLElement).click();
                    }                   
                    this.setState({scheduleData:scheduleData,activeElement:Object.keys(scheduleData).indexOf(weekdate)});
                }
                let elems = document.querySelectorAll('.collapsible');
                M.Collapsible.init(elems);
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        });
    }

    fileUploadHandler = (event) => {
        let file = event.target.files.length !== 0 ? event.target.files[0] : null;
        this.setState({scheduleFile: file});
    }

    addSceduleFromFile = () => {
        if(this.state.scheduleFile){
            let scheduleFile = this.state.scheduleFile;
            let fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                let fileContent = e.target.result;
                let workbook = XLSX.read(fileContent, {type:'binary',raw: true});
                let scheduleJson = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                scheduleJson.map((schedule :any) => {
                    schedule['day'] = days[(new Date(schedule.date).getDay() || 7) - 1]
                    schedule['weekdate'] = this.getWeekDate(new Date(schedule.date));
                    schedule['staff_id'] = this.state.selectedStaff;
                    schedule['location_id'] = localStorage.location_id;
                    return schedule;
                })
                console.log(scheduleJson);
                this.createSchedules(scheduleJson, true);
            }
            fileReader.readAsBinaryString(scheduleFile);
        } 
    }

    sortSchedules = (key1,key2) => {
        let date1 = new Date(key1), date2 = new Date(key2)
        return date1 > date2 ? 1 : -1
      }
    
    sampleDownload = () => {
        this.setState({isLoading: true});
        fetch(basePath+'sampleSchedule',{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
            }
        })
        .then(response => response.ok ? response.blob() : Promise.reject(new Error(response.status.toString())))
        .then(blob => {
            const anchor = document.createElement('a');
            anchor.href = window.URL.createObjectURL(new Blob([blob]));
            anchor.setAttribute('download', 'Sample_Schedule.csv');
            document.body.appendChild(anchor);
            anchor.click();
            anchor.parentNode.removeChild(anchor);
            this.setState({isLoading: false});
        })
        .catch(() => {
            this.setState({isLoading: false});
            this.toaster(false, 'An error occurred and the sample file could not be downloaded!');
        })
    }

    componentDidMount(){
        M.AutoInit();
        let elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems);

        if(this.props.role === 'Admin'){
            this.setState({isLoading: true});
            fetch(basePath+'staffLocation?location_id='+localStorage.location_id,{
                headers:{
                    'Authorization': 'Bearer '+ localStorage.token,
                    ...cacheHeaders,
                }
            })
            .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
            .then(result => {
                if(result.success){
                    if(result.data.length !==0){
                        this.setState({staffList: result.data})
                    }
                    let elems = document.querySelectorAll('#staff_select');
                    M.FormSelect.init(elems);
                }else{
                    this.toaster(false, result.message);
                }
                this.setState({isLoading: false});
            })
            .catch(err => {
                this.toaster(false, 'An error occurred and the action could not be completed!');
                this.setState({isLoading: false});
            });
        }else{
            this.setState({selectedStaff: this.props.userId});
            this.matInitializer();
            this.getSchedules(this.props.userId);
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.activeElement !== this.state.activeElement){
            (document.querySelector('.Schedule.active') as HTMLElement).scrollIntoView();
        }
    }

    render(){

        let options = this.state.staffList.map(staff => 
            (<option key={staff.id} value={staff.staff_id}>
                {staff.first_name+' '+staff.last_name}
            </option>)
        )

        return (
            <>
                <div className='ActionDiv'>
                    <h4>Schedule Builder</h4>
                    {this.props.role === 'Admin' && <button onClick={this.addScheduleHandler} disabled={this.state.selectedStaff === 0} className='btn-flat waves-effect waves-light ActionBtn' type='button' >
                        <i style={{marginRight:'5px'}} className="material-icons left">add</i>
                        Add Schedule
                    </button>}
                </div>
                <div className='SchedulePickers'>
                    {this.props.role === 'Admin' && <div className="input-field col s12 StaffSelect">
                        <select defaultValue='0' id="staff_select" onChange={this.selectChangeHandler}>
                            <option value='0' disabled>Choose one</option>
                            {options}
                        </select>
                        <label style={{fontSize:'16px',color:'#20232a'}}>Pick a Staff</label>
                    </div>}
                    <div className="input-field col s12 DateContainer">
                        <i className="material-icons">today</i>
                        <input id='startDate' disabled={this.state.selectedStaff === 0} type="text" className="datepicker"></input>
                    </div>
                </div>
                {this.props.role === 'Admin' && 
                <div className='FileUploadContainer'>
                    <div className="file-field input-field">
                        <div  style={{color:' var(--secondary-font-color)',boxShadow:'none',padding:'0px'}} className="btn ActionBtn">
                            <i  style={{fontSize:'2rem'}} className="material-icons">attach_file</i>
                            <input id="scheduleFile" disabled={this.state.selectedStaff === 0} type="file" onChange={this.fileUploadHandler} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" placeholder='Upload Schedule csv file' type="text" />
                        </div>
                    </div>
                    <button style={{color:' var(--secondary-font-color)'}} disabled={!this.state.scheduleFile} onClick={this.addSceduleFromFile} className='btn ActionBtn'>Submit</button>
                    <button className='btn ActionBtn' onClick={this.sampleDownload} style={{color:' var(--secondary-font-color)'}} ><i className="material-icons right" style={{marginLeft:'2px'}}>file_download</i>Sample</button>
                </div>}
                {
                    this.state.selectedStaff !== 0 ?
                <div className="ScheduleDiv">
                    {
                        Object.keys(this.state.scheduleData).length !== 0 ?
                        <ul className="collapsible popout">
                        {
                          Object.keys(this.state.scheduleData).sort(this.sortSchedules).map((key,index) => (
                            <Schedule 
                            key={key}  
                            weekDate={key} 
                            active = {index === this.state.activeElement }
                            setActive = {()=>this.setActiveHandler(index)}
                            data={this.state.scheduleData[key]}
                            addAvail = {this.addAvailabilityHandler}
                            deleteAvail = {this.deleteAvailabilityHandler}
                            isAdmin = {this.props.role === 'Admin'} />
                          ))
                        }
                        </ul> : <h6>Schedule Not available</h6>
                    }

                </div> : <h6>Select a staff to view their schedules</h6>
                }
                <div className={'FormContainer card-panel '+ (this.state.formOpen ?'FormOpen' : '')}>
                    <div className='FormHeader'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>{'Add Availability'}</h5>
                        <i style={{cursor:'pointer'}} onClick={this.formOpenHandler} className="material-icons">close</i>
                    </div>
                    <form onSubmit={this.formSubmitHandler} id='ScheduleForm' style={{width:'100%',height:'80%',overflowY:'auto',overflowX:'hidden'}}>
                        <div className="input-field col s12">
                            <input id="start_time" name="start_time" type="text" value={this.state.start_time} onChange={this.inputChangeHandler} onSelect={this.inputChangeHandler} required className="timepicker validate"></input>
                            <label className={this.state.start_time !== '' ? 'active' : ''} htmlFor="start_time">Start Time</label>
                        </div>
                        <div className="input-field col s12">
                            <input id="end_time" name="end_time" type="text" value={this.state.end_time} onChange={this.inputChangeHandler} onSelect={this.inputChangeHandler} required className="timepicker validate"></input>
                            <label className={this.state.end_time !== '' ? 'active' : ''} htmlFor="end_time">End Time</label>
                        </div>
                        <div className="input-field col s12">
                            <input id="frequency" name="frequency" type="number" value={this.state.frequency} onChange={this.inputChangeHandler} min='5' max='60' required autoComplete='off' className="validate"></input>
                            <label className={this.state.frequency !== 0 ? 'active' : ''} htmlFor="frequency">Break in between appointments (mins)</label>
                            <p>* How often to drop a start time between the specified schedule for booking.</p>
                        </div>
                        <div className="input-field col s12">
                            <h6 style={{color:'var(--secondary-font-color)'}}>WeekDays</h6>
                            <div style={{padding:'0px 20px'}} >
                                <p><label htmlFor="Mon">                               
                                        <input id="Mon" checked={this.state.weekDays['Mon']} onChange={this.inputChangeHandler} name="Mon" type="checkbox" />
                                        <span>Monday</span>                               
                                </label></p>
                                <p><label htmlFor="Tue">                               
                                        <input id="Tue" checked={this.state.weekDays['Tue']} onChange={this.inputChangeHandler} name="Tue" type="checkbox" />
                                        <span>Tuesday</span>                               
                                </label></p>
                                <p><label htmlFor="Wed">                               
                                        <input id="Wed" checked={this.state.weekDays['Wed']} onChange={this.inputChangeHandler} name="Wed" type="checkbox" />
                                        <span>Wednesday</span>                               
                                </label></p>
                                <p><label htmlFor="Thu">                               
                                        <input id="Thu" checked={this.state.weekDays['Thu']} onChange={this.inputChangeHandler} name="Thu" type="checkbox" />
                                        <span>Thursday</span>                               
                                </label></p>
                                <p><label htmlFor="Fri">                               
                                        <input id="Fri" checked={this.state.weekDays['Fri']} onChange={this.inputChangeHandler} name="Fri" type="checkbox" />
                                        <span>Friday</span>                               
                                </label></p>
                                <p><label htmlFor="Sat">                               
                                        <input id="Sat" checked={this.state.weekDays['Sat']} onChange={this.inputChangeHandler} name="Sat" type="checkbox" />
                                        <span>Saturday</span>                               
                                </label></p>
                                <p><label htmlFor="Sun">                               
                                        <input id="Sun" checked={this.state.weekDays['Sun']} onChange={this.inputChangeHandler} name="Sun" type="checkbox" />
                                        <span>Sunday</span>                               
                                </label></p>
                            </div>
                            <p>* Other Days of the week, you want to add these times to.</p>
                        </div>
                        <div className='FormAction'>
                            <button style={{color:'#4CAF50'}} className='btn ActionBtn' type='submit' >Save</button>
                            <button style={{color:'#F44336'}} onClick={this.formOpenHandler} className='btn ActionBtn' type='button' >Cancel</button>
                        </div>
                    </form>
                </div>
                {this.state.formOpen && <div onClick={this.formOpenHandler} className='BackDrop'></div>}
                {this.state.isLoading && <Loader />}
            </>
        )
    }
}

export default ScheduleComponent;