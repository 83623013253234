import { Component, Inject, Input,OnChanges } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ExtraServiceService } from "../../../extra-service.service";
import { ReusableService } from "../../../../reusable.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";


export interface DialogData {
  name: string;
  id: string;
  textdata: string;
  imgsrc: string;
  template_id: string;
  _method: string;
  selectedFile: File;
  content:string;
  image:File;
  social_id:string;
}

@Component({
  selector: "app-review-temp",
  templateUrl: "./review-temp.component.html",
  styleUrls: ["./review-temp.component.css"]
})
export class ReviewTempComponent implements  OnChanges {
  @Input() notifier;

  parentdata;
  selectedFile: any = null;
  image: File = null;
  reviewForm: FormGroup;
  formData: any = new FormData();
  SocialArray:any = [{id:'', name:''}];
  submitted = false;
  rnames=['GoogleReview','Fb-Review','BBB','Yelp','Capterra'];
   NAMES = [];
  constructor(
    public dialog: MatDialog,
    private data: ExtraServiceService,
    public reusable: ReusableService,
    public fb: FormBuilder
  ) { }

  // async ngOnInit() {
  //   // this.setupvalidation();
  //   // this.getfromparent();
  //   // this.getsocialInfo();
  //   this.reviewForm = this.fb.group({
  //     name: ['', Validators.required],
  //     content: ['', Validators.required],
  //     link_id: ['', Validators.required],
  //     category_id: [""],
  //     location_id: [""],
  //     avatar: [""],
  //     social_id:['', Validators.required]
  //   });
  
  // }

  ngOnChanges() {
    // create header using child_id
    this.getfromparent();
    this.getsocialInfo();
    this.reviewForm = this.fb.group({
          name: ['', Validators.required],
          content: ['', Validators.required],
          link_id: ['', Validators.required],
          category_id: [""],
          location_id: [""],
          avatar: [""],
          social_id:['', Validators.required]
        });
  }


// setupvalidation(){
  
//   this.reviewForm = new FormGroup({
//     'name': new FormControl('',[Validators.required]),
//     'link_id': new FormControl('',[Validators.required]),
//     'content': new FormControl('',[Validators.required]),
//     'social_id': new FormControl('',[Validators.required])
//   });
// }

  getfromparent() {
    // console.log(this.notifier)
    this.parentdata = this.notifier
    //this.gettemplates();
  }

  openDialog(popdata): void {
    // console.log(popdata);
    const dialogRef = this.dialog.open(popdialogGuest, {
      width: "50%",
      data: {
        name: popdata.name,
        id: popdata.id,
        content: popdata.content,
        image: popdata.image,
        template_link_id: popdata.template_link_id,
        _method: "put",
        selectedFile: popdata.selectedFile
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // console.log(result);

      const Formupdate = new FormData();
      if (result.selectedFile != undefined) {
        Formupdate.append("avatar", result.selectedFile);
      }

      Formupdate.append("id", result.id);
      Formupdate.append("name", result.name);
      Formupdate.append("content", result.content);
      Formupdate.append("link_id", result.template_link_id);
      Formupdate.append("_method", "put");
      //console.log(Formupdate)
      this.data.EditTemplates(Formupdate, result.id).subscribe(
        result => {
          if (result.success == true) {
            // console.log(result);
            this.gettemplates();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.error.message, "Error", "red");
          }
        },
        err => {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(
            "  Cannot connect to the Portal. Please log out and log back in",
            "Error",
            "red"
          );
        }
      );
    });
  }


  getsocialInfo() {
    this.reusable.showspinner();

    if(localStorage.location_id == '')
    {
      window.location.reload()
    }
    else{
      this.data.getsocialdetails(localStorage.location_id).subscribe( 
        result => {
          //console.log(result);
          if (result.success == true) {
  
          //  console.log(result.data);
            this.SocialArray = result.data
            // let results = result.data.map(a => a.type);
            // localStorage.AccountType = results;
  
            
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.error.message, "Error", "red");
          }
        },
        err => {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(
            "  Cannot connect to the Portal. Please log out and log back in",
            "Error",
            "red"
          );
        }
      );
    }


   

  }


  delete(data) {
    // console.log(data);
    this.data.DeleteTemplates(data.id).subscribe(
      result => {
        if (result.success == true) {
          // console.log(result);
          this.gettemplates();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.reviewForm.get("avatar").setValue(this.selectedFile);
  }

  onSubmit() {
    const fd = new FormData();
    fd.append("avatar", this.reviewForm.get("avatar").value);
    fd.append("name", this.reviewForm.get("name").value);
    fd.append("content", this.reviewForm.get("content").value);
    fd.append("category_id", "1");
    fd.append("link_id", this.reviewForm.get("link_id").value);
    fd.append("location_id", localStorage.location_id);
    fd.append("social_id", this.reviewForm.get("social_id").value);
    this.data.createtemplate(fd).subscribe(
      result => {
        // console.log(result);
        if (result.success == true) {
          this.gettemplates();
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  gettemplates() {
    this.reusable.showspinner();
    this.parentdata = [];
    this.data.gettemplates(localStorage.location_id).subscribe(
      result => {
        // console.log(result);
        if (result.success == true) {
          for (let data of result.data) {
            if (data.account_id == 1) {
              this.parentdata.push(data);
            }
          }
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "../popupGuest.html"
})
export class popdialogGuest {
  constructor(
    public dialogRef: MatDialogRef<popdialogGuest>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  selectedFile: any = null;

  reviewForm: FormGroup;
  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    this.data.selectedFile = event.target.files[0];
    // console.log(this.data.selectedFile);
    //  this.reviewForm.get("avatar").setValue(this.selectedFile);
  }
}
