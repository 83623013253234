import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module/material-module';
import { OverviewComponent } from './overview/overview.component';
import { RequestComponent } from './request/request.component';
import { ViewComponent } from './view/view.component';
import { ChartsModule } from 'ng2-charts';
import { BargraphComponent } from './overview/bargraph/bargraph.component';
import { LinegraphComponent } from './overview/linegraph/linegraph.component';
import { ViewreviewComponent } from './view/viewreview/viewreview.component';
import { ViewcustomComponent } from './view/viewcustom/viewcustom.component';
import { ViewbreezebackComponent } from './view/viewbreezeback/viewbreezeback.component';
import { ViewbackcouponComponent } from './view/viewbackcoupon/viewbackcoupon.component';
import { ViewbackvipComponent } from './view/viewbackvip/viewbackvip.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { ReputationComponent,reply } from './view/reputation/reputation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CheckLengthPipe } from '../check-length.pipe';
import { AlertbargraphComponent } from './overview/alertbargraph/alertbargraph.component';
import { AlertlinegraphComponent } from './overview/alertlinegraph/alertlinegraph.component';
import { AlertComponent } from './view/alert/alert.component';
@NgModule({
  declarations: [OverviewComponent, RequestComponent, ViewComponent, BargraphComponent, LinegraphComponent, ViewreviewComponent, ViewcustomComponent, ViewbreezebackComponent, ViewbackcouponComponent, ViewbackvipComponent, ReputationComponent,reply,CheckLengthPipe, AlertbargraphComponent, AlertlinegraphComponent, AlertComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ChartsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  entryComponents: [reply],
  exports: [OverviewComponent,RequestComponent,ViewComponent,BargraphComponent,ViewreviewComponent,ViewcustomComponent,ViewbreezebackComponent,ViewbackcouponComponent,ViewbackvipComponent,CheckLengthPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ReviewModuleModule { }
