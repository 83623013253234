import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ReusableService } from 'src/modules/reusable.service';
import { ReviewServiceService } from '../../review-service.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  filterData=[];
  ELEMENT_DATA: PeriodicElement[] = [];
  INITIAL_DATA: PeriodicElement[] = [];

  displayedColumns: string[] = ['first_name', 'number', 'email', 'appointment','sent_date','message_status','sent_by'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  background = '';
  colors = '';

  pipe: DatePipe;
  
  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
});
  
get fromDate() { return this.filterForm.get('fromDate').value; }

get toDate() { return this.filterForm.get('toDate').value; }

  constructor(
    
    public reusable: ReusableService,
    private data: ReviewServiceService,

  ) {
    this.pipe = new DatePipe('en');

    this.dataSource.filterPredicate = (data, filter) =>{
      console.log(this.fromDate);
      if (this.fromDate && this.toDate) {
        return data.sent_date >= this.fromDate && data.sent_date <= this.toDate;
      }
      return true;
    }

   }

  ngOnInit() {
    this.data.updateListsObs1
    .subscribe(
    (response) => {this.getTemplateData()}
    )
    
    this.background = 'lightskyblue';
    this.colors = 'white';
    this.getTemplateData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.INITIAL_DATA.length !== 0){
      this.filterData=this.INITIAL_DATA.filter((data)=>{         
        return (data.first_name.trim().toLowerCase().includes(filterValue.trim().toLowerCase())||data.number.trim().toLowerCase().includes(filterValue.trim().toLowerCase())||data.sent_by.trim().toLowerCase().includes(filterValue.trim().toLowerCase()));             
      });
    }          
    this.ELEMENT_DATA = this.filterData;
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyDateFilter() {
    if(this.INITIAL_DATA.length !== 0){
      this.filterData=this.INITIAL_DATA.filter((data)=>{
        return ((new Date(data.sent_date).getTime() >= this.fromDate.getTime() && new Date(data.sent_date).getTime() <= this.toDate.getTime()));
      });
      this.ELEMENT_DATA = this.filterData;
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  getTemplateData() {
    this.reusable.showspinner();

    this.data.getalertviewlist(localStorage.location_id).subscribe(
      result => {
        if (result.success == true) {
          this.INITIAL_DATA = [...result.data];
          this.ELEMENT_DATA = result.data;
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }


}

export interface PeriodicElement {
  number: string;
  first_name: string;
  appointment: string;
  sent_date: Date;
  sent_by:string;
  message_status:string;
}

