import React from 'react'
import './themeSelector.css'

import Themes from '../theme'

const ThemeSelector = (props) => {
    return (
        <>
        <div className='ActionDiv'>
            <h4>Display Settings</h4>
        </div>
        <div className='ThemeSelector'>
            <h5 style={{color:'var(--secondary-font-color)'}}>Themes</h5>
            <div className='ThemeContainer'>
            {
                Object.keys(Themes).map(theme => {
                    let themeObj = Themes[theme];
                    let classes = ['Theme'];
                    props.currentTheme === theme && classes.push('Selected')
                    return (
                    <div key={theme} onClick={()=>props.change(theme)} style={{background: `linear-gradient(-45deg,${themeObj['primary-bg-color']} 50%,${themeObj['secondary-bg-color']} 50%)`}} className={classes.join(' ')}>
                        <h6 style={{fontWeight:'bold',marginLeft:'8px', color: themeObj['primary-bg-color']}}>{theme}</h6>
                    </div>
                    )
                })
            }
            </div>
        </div>
        </>
    )
}

export default ThemeSelector;