import { Component, OnInit,Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { ReusableService } from "../../../reusable.service";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-linegraph',
  templateUrl: './linegraph.component.html',
  styleUrls: ['./linegraph.component.css']
})
export class LinegraphComponent implements OnInit {
  requestyear=[];
  pendingyear=[];
  completedyeardata = [];
  months = [];
  datapending=[];
  datarequest = [];
  datacompletedyeardata = [];
  @Input() notifier: Subject<boolean>;
  
  parentdata;

  public lineChartData: ChartDataSets[] = [
    { data: this.datapending, label: 'Pending' },
    { data:this.datarequest, label: 'Request' },
    { data:this.datacompletedyeardata, label: 'Completed' },
  ];
  
  
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [{}]
    },
    annotation:{},
  };

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  public lineChartLabels: Label[] = this.months;
  
 

  constructor(public reusable: ReusableService) { }

  ngOnInit() {
    this.notifier.subscribe(v => { 
      //console.log('value is changing', v);
      this.parentdata = v;
      this.getlinegraphdata(); 
   });
   
  }

    getlinegraphdata(){
    this.reusable.showspinner();

        //console.log(this.parentdata);
        if (this.parentdata.success == true) {

          for(var k in this.parentdata.data.review.pending){
            this.months.push(k);
          } 

          this.pendingyear= this.parentdata.data.review.pending
          this.requestyear= this.parentdata.data.review.request
          this.completedyeardata= this.parentdata.data.review.completed
         

          for (var key in this.pendingyear) {
            this.datapending.push(this.pendingyear[key]);
          }

          for (var key in this.requestyear) {
            this.datarequest.push(this.requestyear[key]);
          }

          for (var key in this.completedyeardata) {
            this.datacompletedyeardata.push(this.completedyeardata[key]);
          }

          this.lineChartData = [
            { data:this.datapending, label: 'Pending' },
            { data: this.datarequest, label: 'Request' },
            { data:this.datacompletedyeardata, label: 'Completed' },
          ];
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(this.parentdata.error.message, "Error", "red");
        }

  }
}
