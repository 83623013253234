import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module/material-module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component'; // this is for the material Module
import {ReviewDashComponent} from '../review-modules/review-dash/review-dash.component';
import { SettingsComponent } from './settings/settings.component'
import {ExtraRoutingModule} from './extra-routing.module';
import { RatingLandingPageSettingsComponent } from './settings/rating-landing-page-settings/rating-landing-page-settings.component';
import { TemplatesComponent } from './settings/templates/templates.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { ReviewTempComponent,popdialogGuest } from './settings/templates/review-temp/review-temp.component';
import { AlertTempComponent,popdialogAlert } from './settings/templates/alert-temp/alert-temp.component';
import { BackTempComponent,popdialogBack } from './settings/templates/back-temp/back-temp.component';
import { BackCouponTempComponent } from './settings/templates/backCoupon-temp/backCoupon-temp.component';
import { BackVipTempComponent } from './settings/templates/backVip-temp/backVip-temp.component';
import { ColorPickerModule } from 'ngx-color-picker';
import {ReviewModuleModule} from '../review-modules/review-module.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { QrcodeComponent } from './settings/qrcode/qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    ReviewDashComponent, 
    SettingsComponent, 
    RatingLandingPageSettingsComponent, 
    TemplatesComponent, 
    ProfileComponent,
    popdialogGuest, 
    ReviewTempComponent, 
    AlertTempComponent,
    popdialogAlert,
    BackTempComponent,
    BackCouponTempComponent,
    BackVipTempComponent,
    popdialogBack,
    QrcodeComponent,
    
  
  ],

  imports: [
    CommonModule,
    MaterialModule,
    ExtraRoutingModule,
    FormsModule,
    ColorPickerModule,
    ReviewModuleModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    QRCodeModule
  ],
  entryComponents: [popdialogGuest,popdialogAlert,popdialogBack],
  exports:[FooterComponent,HeaderComponent,ReviewDashComponent,SettingsComponent]
})
export class ExtraModuleModule { }
