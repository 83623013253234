import { Component, OnInit,Input } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { ReusableService } from 'src/modules/reusable.service';
import { AuthServiceService } from '../auth-service.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-leavereview',
  templateUrl: './leavereview.component.html',
  styleUrls: ['./leavereview.component.css']
})
export class LeavereviewComponent implements OnInit {

  number;
  hash;
  name;
  id;
  website;
  negativeform: FormGroup;
  constructor(private route: ActivatedRoute,public reusable: ReusableService,
    private data: AuthServiceService, public fb: FormBuilder, private router: Router,) { 
  this.hash =  this.route.params;
  // console.log(this.hash.value.id)
  }

  color1 = "#fff";
  btncolor = "#83aff8";
  link;
  @Input() Rating_landing_message =
    " It was a pleasure assisting you. Please tell us how we did!";
  @Input() bottom_text =
    "Select a star rating and please wait while we redirect you to leave your written review.Thank You in Advance!";

  public imagePath;
  imgURL: any = "assets/breezereviewlogo.png";
  public message: string;

  public submitted = false;

  ngOnInit() {
    this.setupvalidation();
    this.negativeform = this.fb.group({
      name: [""],
   reason:[""]
    });
    this.getLandingReview()
  }


  setupvalidation(){
    this.negativeform = new FormGroup({
      'reason': new FormControl('',[Validators.required])
    });
  }

  
  check(numbers){
   this.number = numbers;
  }

  submitRating(){
    this.reusable.showspinner();

    this.data.postLanding(this.number,this.id).subscribe(
      result => {
        if (result.success == true) {
          this.reusable.hidespinner();
          this.submitted = true;
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  getLandingReview() {
    this.reusable.showspinner();

    this.data.getLanding(this.hash.value.id).subscribe(
      result => {
        if (result.success == true) {
          this.id = result.data.id;
         this.imgURL = result.data.image;
         this.message = result.data.message;
         this.name = result.data.name;
         this.color1 = result.data.bgcolor;
         this.link = result.data.url;
         this.website = result.data.website;
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.submitted = true;        
          this.reusable.openSnackBar('You have already submited your review','','');
          window.open(this.website);
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }



  onSubmit() {
    this.reusable.showspinner();
    const fd = new FormData();
    fd.append("message", this.negativeform.get("reason").value);
    fd.append("id", this.id);
   
    this.data.negativeReputation(fd).subscribe(
      result => {
        if (result.success == true) {
          this.submitRating();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }
}
