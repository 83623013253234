<div class="mat-elevation-z8" style="width: 100vw; margin-top: 30px;overflow:auto;">

  <form class="date-block" [formGroup]="filterForm">
    <div class="date-block__list">
      <mat-form-field>
        <input matInput 
          [matDatepicker]="fromDate" 
          placeholder="From Date" 
          formControlName="fromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate></mat-datepicker>
      </mat-form-field>
    </div>
      <div class="date-block__list">
      <mat-form-field>
        <input matInput 
          [matDatepicker]="toDate" 
          placeholder="To Date" 
          formControlName="toDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
     <button mat-button color="primary" class="primary" (click)="applyDateFilter()">Filter</button>
</form>

    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
      </mat-form-field>

    <table #filteredTable mat-table [dataSource]="dataSource" matSort  style="border-radius: 50px;">
  
      <!-- Position Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Number </th>
        <td mat-cell *matCellDef="let element"> {{element.number}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="sent_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sent Date </th>
        <td mat-cell *matCellDef="let element"> {{element.sent_date}} </td>
      </ng-container>

      <ng-container matColumnDef="message_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Message Status </th>
        <td mat-cell *matCellDef="let element"> {{element.message_status}} </td>
      </ng-container>
       
      <!-- Weight Column -->
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
        <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
