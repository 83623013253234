<div *ngIf="dashboard === 'review' && active">
  <div style="margin-top: 50px !important;" class="analyticContent" *ngIf ="total_request_review != null">
    <div class="contentHolder">
        <div class="numData">{{total_request_review}}</div>
        <div class="numTitle">Current Month Request</div>
    </div>
    <div class="contentHolder">
            <div class="numData">{{pending_request_review}}</div>
            <div class="numTitle">Current Month Pending</div>
    </div>
    <div class="contentHolder">
            <div class="numData">{{this_month_request_review}}</div>
            <div class="numTitle">Completed</div>
    </div>
    <div class="contentHolder">
            <div class="numData">{{target_review}}</div>
            <div class="numTitle">Target</div>
    </div>

</div>
<div class="row" style="margin: 20px;" class="dontshow">
    <div class="col-md-6">
        <app-linegraph [notifier]="childNotifier"></app-linegraph>
    </div>
    <div class="col-md-6">
        <app-bargraph [notifier]="childNotifier"></app-bargraph>
    </div>
        
  </div>
  <ngx-spinner  [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading">Loading Data...</p>
  </ngx-spinner>
</div>  
<div *ngIf="active && dashboard === 'alert' ">
  <div style="margin-top: 50px !important;" class="analyticContent" *ngIf ="current_delivered != null">
    <div class="contentHolder">
        <div class="numData">{{current_delivered}}</div>
        <div class="numTitle">Total Sent</div>
    </div>
    <div class="contentHolder">
            <div class="numData">{{current_cancelled}}</div>
            <div class="numTitle">Cancelled</div>
    </div>
    <div class="contentHolder">
            <div class="numData">{{current_rescheduled}}</div>
            <div class="numTitle">Rescheduled</div>
    </div>

  </div>
  <div class="row" style="margin: 20px;" class="dontshow">
    <div class="col-md-6">
        <app-alertlinegraph [notifier]="childNotifier"></app-alertlinegraph>
    </div>
    <div class="col-md-6">
        <app-alertbargraph [notifier]="childNotifier"></app-alertbargraph>
    </div>
        
  </div>
  <ngx-spinner  [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading">Loading Data...</p>
  </ngx-spinner>
</div>
<div *ngIf="!active">
  <div style="margin:10vh 0px; justify-content:center;" class='InfoTip'>
    <i style="color:Orange;font-size:30px;" class='material-icons'>report_problem</i>
    <span style="font-size:20px;">You haven't registered for this service. Please <a (click)="activateAccount($event)" href="#">click here</a> to activate.</span>
  </div>
  <ngx-spinner></ngx-spinner>
</div>



