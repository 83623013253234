import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import {AppServiceService} from '../app-service.service'; // this.apiservice.APIEndPoint  this.apiservice.httpHeaderOptions

@Injectable({
  providedIn: 'root'
})


export class ReviewServiceService {

  alertreviewTable : Subject<any> = new Subject<any>();
  updateListsObs = <Observable<any>>this.alertreviewTable;

  alertreviewTable1 : Subject<any> = new Subject<any>();
  updateListsObs1 = <Observable<any>>this.alertreviewTable1;

  updateListFn(id) {
    // console.log("called " + id);
    this.alertreviewTable.next(this.http.get<any>(this.apiservice.APIEndPoint + 'review?location_id='+id, this.apiservice.httpHeaderOptions));
   }

   updateListFnAlert(id) {
    // console.log("called reminder" + id);
    this.alertreviewTable1.next(this.http.get<any>(this.apiservice.APIEndPoint + 'reminder?location_id='+id, this.apiservice.httpHeaderOptions));
   }


  
  location_id = localStorage.location_id;
  constructor(private http: HttpClient,public apiservice : AppServiceService) { }

  // get all the API information
  getoverview(lid): Observable<any> {
    return this.http.get<any>(this.apiservice.APIEndPoint + 'overview?location_id='+lid, this.apiservice.httpHeaderOptions);
 }

 //get all the template data

 gettemplatedata(lid): Observable<any> {
  // console.log("2")
  // this is used for home requst page 
  return this.http.get<any>(this.apiservice.APIEndPoint + 'template?location_id='+lid, this.apiservice.httpHeaderOptions);
}

//sending the message
sendMessage(): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'overview', this.apiservice.httpHeaderOptions);
}


getviewlist(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'review?location_id='+lid, this.apiservice.httpHeaderOptions);
}

sendviewlist(data): Observable<any> {
  return this.http.post<any>(this.apiservice.APIEndPoint + 'review',data, this.apiservice.httpHeaderOptions);
}

getalertviewlist(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'reminder?location_id='+lid, this.apiservice.httpHeaderOptions);
}


getreputationlist(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'negative_review?location_id='+lid, this.apiservice.httpHeaderOptions);
}

replyreputation(id,reply): Observable<any> {
  return this.http.post<any>(this.apiservice.APIEndPoint + 'negative_review/'+id,reply, this.apiservice.httpHeaderOptions);
}


getviewlistBack(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'back?location_id='+lid, this.apiservice.httpHeaderOptions);
}

getviewlistCoupon(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'coupon?location_id='+lid, this.apiservice.httpHeaderOptions);
}

getviewlistVip(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'vip?location_id='+lid, this.apiservice.httpHeaderOptions);
}

getBookServiceList(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint + 'service?location_id='+lid, this.apiservice.httpHeaderOptions);
}


}
