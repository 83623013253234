import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
//Modules import here
import { MaterialModule } from './material-module/material-module'; // this is for the material Module
import { AuthModuleModule } from './authenticate/auth-module.module'; // this is the login/forget password
import { AlertModuleModule } from './alert-modules/alert-module.module'; // this is used for alert modules
import { ExtraModuleModule } from './extra-modules/extra-module.module'; // this is all the extra modules
import { MasterAccountModuleModule } from './master-accout-module/master-account-module.module';
import { ReviewModuleModule } from './review-modules/review-module.module';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
// This is all the  
import { LoginRoutingModule } from './authenticate/login-routing.module';
import { ExtraRoutingModule } from './extra-modules/extra-routing.module';
import { MasterRoutingModule } from './master-accout-module/master-routing.module';
import { ReviewRoutingModule } from './review-modules/review-routing.module';
import { AlertRoutingModule } from './alert-modules/alert-routing.module';
//Module import Ends here
// Authnetication Module where the JWT token is declared
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HomeComponent } from './home/home.component';
import { CommonComponent } from './common/common.component';
import { BreezeBookComponent } from './breezeBook/breezeBook.component';
// Jwt token declaration Ends here
//Routing wont work if you dont have the HTTPclient
import { HttpClientModule } from '@angular/common/http'; 
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CommonComponent,
    BreezeBookComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AuthModuleModule,
    AlertModuleModule,
    ExtraModuleModule,
    MasterAccountModuleModule,
    ReviewModuleModule,
    AppRoutingModule,
    FlexLayoutModule,
    LoginRoutingModule,
    ExtraRoutingModule,
    MasterRoutingModule,
    ReviewRoutingModule,
    AlertRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
    
  ],
  providers: [ { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
