<div class="header_component">
  <div class="header_wrapper">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Overview"><app-rev></app-rev></mat-tab>
      <mat-tab label="Overview"><app-rev></app-rev></mat-tab>
      <mat-tab label="Request">Content 2</mat-tab>
      <mat-tab label="View">Content 3</mat-tab>
    </mat-tab-group>
  </div>
 
</div>
