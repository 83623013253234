import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from "@angular/common/http";
import { Observable } from "rxjs";
import {AppServiceService} from '../app-service.service'; // this.apiservice.APIEndPoint  this.apiservice.httpHeaderOptions
import {ReusableService} from '../reusable.service'
@Injectable({
  providedIn: 'root'
})
export class ExtraServiceService {

  constructor(private http: HttpClient,private apiservice: AppServiceService,private reuse:ReusableService) { }

  getlocationdata(): Observable<any> {
   return this.http.get<any>(this.apiservice.APIEndPoint+ 'locations',this.apiservice.httpHeaderOptions)
  }

  gettemplates(lid): Observable<any> {
    // console.log("1 this ")
    return this.http.get<any>(this.apiservice.APIEndPoint+ 'template?location_id='+lid,this.apiservice.httpHeaderOptions)
   }

   createtemplate(data): Observable<any> {
    //  console.log(data);
    return this.http.post<any>(this.apiservice.APIEndPoint + 'template',data, this.apiservice.httpHeaderOptions);
  }

  Profile(data): Observable<any> {
    // console.log(data);
   return this.http.post<any>(this.apiservice.APIEndPoint + 'profile',data, this.apiservice.httpHeaderOptions);
 }
  

 EditTemplates(data,id): Observable<any> {
  return this.http.post<any>(this.apiservice.APIEndPoint + 'template/'+id,data, this.apiservice.httpHeaderOptions);
}

DeleteTemplates(id): Observable<any> {
  return this.http.delete<any>(this.apiservice.APIEndPoint + 'template/'+id, this.apiservice.httpHeaderOptions);
}


postratingpagesettings(data,id): Observable<any> {
  // console.log(data);
  return this.http.post<any>(this.apiservice.APIEndPoint + 'ratingSettings/'+id,data, this.apiservice.httpHeaderOptions);
}


getratingsdata(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint+ 'ratingSettings?location_id='+lid,this.apiservice.httpHeaderOptions)
 }

 getalertdata(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint+ 'alertSettings?location_id='+lid,this.apiservice.httpHeaderOptions)
 }

 postalertpagesettings(data,id): Observable<any> {
  // console.log(data);
  return this.http.post<any>(this.apiservice.APIEndPoint + 'alertSettings/'+id,data, this.apiservice.httpHeaderOptions);
}


getaccountdetails(data): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint+ 'locationAccount?location_id='+data,this.apiservice.httpHeaderOptions)
 }


 getsocialdetails(id): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint+ 'locationSocial?location_id='+id,this.apiservice.httpHeaderOptions)
 }


 getbackdata(lid): Observable<any> {
  return this.http.get<any>(this.apiservice.APIEndPoint+ 'backSettings?location_id='+lid,this.apiservice.httpHeaderOptions)
 }

 postbackpagesettings(data,id): Observable<any> {
  // console.log(data);
  return this.http.post<any>(this.apiservice.APIEndPoint + 'backSettings/'+id,data, this.apiservice.httpHeaderOptions);
}

}
