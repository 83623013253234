import { Component, OnInit ,Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { ReusableService } from "../../../reusable.service";
import { Subject } from 'rxjs';
@Component({
  selector: 'app-bargraph',
  templateUrl: './bargraph.component.html',
  styleUrls: ['./bargraph.component.css']
})
export class BargraphComponent implements OnInit {

  requestyear=[];
  pendingyear=[];
  completedyeardata = [];
  months = [];
  datapending=[];
  datarequest = [];
  datacompletedyeardata = [];

  @Input() notifier: Subject<boolean>;
  parentdata;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  
  public barChartLabels: Label[] = this.months;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data:this.datapending, label: 'Pending' },
    { data: this.datarequest, label: 'Request' },
    { data: this.datacompletedyeardata, label: 'Completed' },
  ];

  constructor(public reusable: ReusableService) { }

  ngOnInit() {
    this.notifier.subscribe(v => {
      this.parentdata = v;
      this.getbargraphdata(); 
   });
  }


  getbargraphdata(){
    this.reusable.showspinner();
        if (this.parentdata.success == true) {

          for(var k in this.parentdata.data.review.pending){
            this.months.push(k);
          } 

          this.pendingyear= this.parentdata.data.review.pending;
          this.requestyear= this.parentdata.data.review.request;
          this.completedyeardata = this.parentdata.data.review.completed;
         

          for (var key in this.pendingyear) {
            this.datapending.push(this.pendingyear[key]);
          }

          for (var key in this.requestyear) {
            this.datarequest.push(this.requestyear[key]);
          }

          for (var key in this.completedyeardata) {
            this.datacompletedyeardata.push(this.completedyeardata[key]);
          }

          this.barChartData = [
            { data:this.datapending, label: 'Pending' },
            { data: this.datarequest, label: 'Request' },
            { data: this.datacompletedyeardata, label: 'Completed' },
          ];
          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(this.parentdata.error.message, "Error", "red");
        }
      }

  }
