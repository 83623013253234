import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from '../home/home.component'
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { QrcodeComponent} from './settings/qrcode/qrcode.component'
import { AuthServiceService as AuthGuard } from '../authenticate/auth-service.service';

const routes: Routes = [
  { path: 'settings', component: SettingsComponent,canActivate: [AuthGuard] },
  { path: 'QR', component: QrcodeComponent,canActivate: [AuthGuard] },
   { path: '', component: HomeComponent,canActivate: [AuthGuard],
  children:[
    
      {path:'',redirectTo:'login',pathMatch:'full'},
      { path: 'dashboard', component: DashboardComponent }
    
     

  ]
  
  },
  // { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ExtraRoutingModule { }