import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ForgotComponent} from './forgot/forgot.component'
import {HomeComponent} from '../home/home.component';
import { LeavereviewComponent } from './leavereview/leavereview.component';
import { BusinessReviewComponent } from './businessReview/businessReview.component';
import { AuthServiceService as AuthGuard } from '../authenticate/auth-service.service';
import {AlertpageComponent} from './alertpage/alertpage.component';
import { BackreviewComponent } from './backreview/backreview.component';

const routes: Routes = [
   { path: 'forgot', component: ForgotComponent },
   { path: 'alert/:id', component: AlertpageComponent },
   { path: 'leavereview/:id', component: LeavereviewComponent },
   { path: 'businessReview/:location/:social', component: BusinessReviewComponent },
   { path: 'backreview/:id', component: BackreviewComponent },
   { path: 'home', component: HomeComponent,canActivate: [AuthGuard] },
  // { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }