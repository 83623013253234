<div class="header_component">
    <div class="header_wrapper" style="color: black;">
        <button mat-button id="locations" [matMenuTriggerFor]="appMenu"> <mat-icon aria-hidden="false"  aria-label="Example icon" style="color:grey ;">store</mat-icon>{{locationnamedata}}
        </button>
        <mat-menu #appMenu="matMenu" >
            <button mat-menu-item *ngFor="let locations of locations_data" (click)="setlocation(locations.id);">{{locations.title}}</button>
        </mat-menu>
          
        <div class="user_info">
                <div class="user-elements logo">
                 <img src={{logo}} alt="logo">
                </div>
                <div class="user-elements">
                    <div id="username">{{name}}</div>
                    <div id="userTitle">{{designation}}</div>
                </div>
                <div class="user-elements">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon aria-hidden="false" aria-label="Example icon" style="color:#83aff8 ;">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink] = "['/home']">Home</button>
                        <button mat-menu-item [routerLink] = "['/settings']">Settings</button>
                        <button mat-menu-item>Help</button>
                        <!-- <button mat-menu-item [routerLink] = "['/QR']">Your QRcode</button> -->
                        <button mat-menu-item (click)="logout()">Logout</button>
                      </mat-menu>
                </div>
        </div>

    </div>
</div>