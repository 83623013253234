<div class="container">
  <div class="item one">
    <div class="contentwrapper" [formGroup]="ForgetForm">
      <div class="logo">
        <img class="imagelogo" src="assets/Logo.png" alt="" />
      </div>
      <div class="title">Take Control of Your Reputation Before Others Do</div>
      <div class="form">
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            formControlName="email"
            [ngClass]="{ error: ForgetForm.controls.email.errors?.email }"
            #username
          />
      
          <mat-error *ngIf="ForgetForm.controls.email.errors?.email">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="ForgetForm.controls.email.errors?.required">
            Email is required
          </mat-error>
        </mat-form-field>

    
      </div>
      <div class="controls">
        <div class="forget">
          <a [routerLink]="['/login']">Login?</a>
        </div>
        <div class="login">
          <button type="submit" class="btn btn-default btnlogin" (click)="login()">Reset Password</button>
        </div>
      </div>
    </div>
  </div>

  <div class="item two"></div>
</div>
