import { NgModule } from '@angular/core';
import { RouterModule, Routes,PreloadAllModules } from '@angular/router';
import { AppComponent } from './app.component';
import {LoginComponent} from './authenticate/login/login.component';
import {ReviewDashComponent} from './review-modules/review-dash/review-dash.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'review', component: ReviewDashComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }