




  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Pending data
        </mat-panel-title>
        <mat-panel-description>
        
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="mat-elevation-z8" style="width: 100%; margin-top: 30px;overflow:auto;">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
    
        <table mat-table [dataSource]="dataSource" matSort  style="border-radius: 50px;">
      
          <!-- Position Column -->
         <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container> 
      
          <!-- Name Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Number </th>
            <td mat-cell *matCellDef="let element"> {{element.number}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
          </ng-container>

          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
            <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
          </ng-container>

          <ng-container matColumnDef="view_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.view_date}} </td>
          </ng-container>
    
    
        
      
    
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let element">   <button id="Submit" (click)="openDialog(element)" type="submit">Reply</button> </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>  
      <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Resolved  data
        </mat-panel-title>
        <mat-panel-description>
          
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="mat-elevation-z8" style="width: 100%; margin-top: 30px;overflow:auto;">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
    
        <table mat-table [dataSource]="dataSource2" matSort  style="border-radius: 50px;">
      
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Number </th>
            <td mat-cell *matCellDef="let element"> {{element.number}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
          </ng-container>    

          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating </th>
            <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
          </ng-container>

          <ng-container matColumnDef="view_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.view_date}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let element">   <button id="Submit" (click)="openDialog(element)" type="submit">View</button> </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
        <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>    
    </mat-expansion-panel>
    <ngx-spinner></ngx-spinner>
  </mat-accordion>