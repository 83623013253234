import * as React from 'react';
import './breezebook.css';
import {cacheHeaders,basePath} from './config'
import Themes from './theme'

import ServiceComponent from './serviceComponent/serviceComponent'
import StaffComponent from './staffComponent/staffComponent'
import LocationStaffComponent from './locationStaffComponent/locationStaffComponent'
import ServiceStaffComponent from './serviceStaffComponent/serviceStaffComponent'
import ScheduleComponent from './scheduleComponent/scheduleComponent'
import AppointmentComponent from './appointmentComponent/appointmentComponent'
import PendingAppointments from './pendingAppointments/pendingAppointments'
import ThemeSelector from './ThemeSelector/themeSelector'
import Loader from './loader/loader'


interface PassedPropsState {
    [key: string]: any
  }

const menuCount = 7;

class BreezeBook extends React.Component<PassedPropsState,PassedPropsState>{
    
    state={
        selectedIndex: 0,
        menuOpen: false,
        navigationOpen: true,
        pendingData: [],
        statusTracker: 0,
        hasPaypal: false,
        currency: 'CAD',
        theme: 'Night',
        isLoading: true
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    sendActivationMail = (ev) => {
        ev.preventDefault();
        this.setState({isLoading: true});
        fetch(basePath+'contact?location_title='+localStorage.location_name+'&account_type=BreezeBook',{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                'Content-Type': 'application/json',
                ...cacheHeaders,
            },
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            let message = result.message;
            if(result.success){
                message = "Activation Mail received. You will be contacted shortly!";
            }
            this.toaster(result.success,message);
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.setState({isLoading: false});
            this.toaster(false,'An error occurred and the action could not be completed!');
        })
    }

    menuClickHandler = () => {
        this.setState((prevState)=>(
            {menuOpen: !prevState.menuOpen}
        ))
    }

    navBtnClickHandler = () => {
        this.setState(prevState => (
            {navigationOpen: !prevState.navigationOpen}
        ))
    }

    getCurvature = (index) => {
        return (240+Math.cos((index*(Math.PI/(4*menuCount)))+(Math.PI/2))*300)
    }

    pendingStatusChangeHandler= (id, status) => {
        this.setState({isLoading:true});
        let postData={
            status,
            _method: 'put'
        }
        fetch(basePath+'appointment/'+id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                'Content-Type': 'application/json',
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                let message = ''
                if(status === 'Confirmed')
                    message = 'Cancellation Request has been rejected';
                else if(status === 'Cancelled')
                    message = 'Appointment cancelled';
                this.toaster(true,message);
                this.getPendingAppointments();
            }else{
                this.toaster(false,result.message);
                this.setState({isLoading: false});
            }               
        })
        .catch(err => {
            this.setState({isLoading: false});
            this.toaster(false,'An error occurred and the action could not be completed!');
        })
    }

    getPendingAppointments = () => {
        this.setState({isLoading:true});
        let apiKey= 'pendingAppointment';
        let queryString = '?location_id='+localStorage.location_id;
        if(this.props.role !== 'Admin'){
            apiKey = 'staffPendingAppointment';
            queryString = queryString + '&staff_id=' + localStorage.userId;
        }
        fetch(basePath+apiKey+queryString,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({pendingData: result.data, isLoading: false})
            }else{
                this.toaster(result.success,result.message);
                this.setState({isLoading:false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the Appointment cancellation requests could not be fetched!');
            this.setState({isLoading:false});
        });
    }

    trackStatusChange = () => {
        this.setState((prevState)=>(
            {statusTracker: prevState.statusTracker+1}
        ));
    }

    getPaymentStatus = () => {
        this.setState({isLoading: true});
        fetch(basePath+'locationPaymentInfo?location_id='+ localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({hasPaypal: !!result.data.hasPaypal, currency:result.data.currency,isLoading: false});
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    refundPayment = (appointment_id,amount,_callback) => {
        this.setState({isLoading: true});
        let postData = {
            appointment_id,
            amount
        }
        fetch(basePath+'appointmentRefund',{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                'Content-Type': 'application/json',
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.toaster(true, 'Amount has been refunded');
                this.setState({isLoading: false});
                _callback();
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'Refund Action Failed');
            this.setState({isLoading: false});
        })
    }

    getTheme = () => {
        this.setState({isLoading: true});
        fetch(basePath+'theme?company_name='+ localStorage.compName,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.applyTheme(result.data[0].theme);
                this.setState({isLoading: false,theme: result.data[0].theme});
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    applyTheme = (mode) => {
        const theme = Themes[mode];
        Object.keys(theme).forEach(key => {
            document.documentElement.style.setProperty(`--${key}`,theme[key]);
        })
    }

    changeTheme = (theme) => {
        if(this.state.theme !== theme){
            this.setState({isLoading: true});
            fetch(basePath+'setTheme?company_name='+ localStorage.compName+'&theme='+theme,{
                headers:{
                    'Authorization': 'Bearer '+ localStorage.token,
                    ...cacheHeaders,
                }
            })
            .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
            .then(result => {
                if(result.success){
                    this.applyTheme(theme);
                    this.setState({isLoading: false,theme: theme});
                }else{
                    this.toaster(false, result.message);
                    this.setState({isLoading: false});
                }
            })
            .catch(err => {
                this.toaster(false, 'An error occurred and the action could not be completed!');
                this.setState({isLoading: false});
            })
        }
    }

    componentDidMount(){
        if(this.props.active){
            if(this.props.role !== 'Admin'){
                this.setState({selectedIndex:4});         
            }
            this.getPendingAppointments();
            // For getting payment status
            this.getPaymentStatus();
            this.getTheme();
        }else{
            this.setState({isLoading: false});
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.active && this.state.statusTracker !== prevState.statusTracker){
            this.getPendingAppointments();
        }
    }
    
    render(){
        let isAdmin: boolean = this.props.role === 'Admin';
        let navigationClasses = ['NavigationBar'];
        let pageWrapperClasses = ['PageWrapper'];
        let mobileMenuClasses = ['MobileMenu'];
        if(!isAdmin){
            navigationClasses.push('StaffView');
            mobileMenuClasses.push('StaffView');
        }
        this.state.menuOpen && mobileMenuClasses.push('MenuOpen')
        this.state.navigationOpen ? navigationClasses.push('NavigationOpen') : pageWrapperClasses.push('PageExpand')
        
        return(
            <>
            {this.props.active ?
            <div className='BreezeBook'>
                <div className={navigationClasses.join(' ')}>
                    {isAdmin && <div className='NavItem' onClick={() => this.setState({selectedIndex:0})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">phonelink_setup</i>
                        <h6>Services</h6>                       
                    </div>}
                    {isAdmin && <div className='NavItem' onClick={() => this.setState({selectedIndex:1})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">person</i>
                        <h6>Staff</h6>
                    </div>}
                    {isAdmin && <div className='NavItem' onClick={() => this.setState({selectedIndex:2})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">person_pin_circle</i>
                        <h6>Location-Staff</h6>
                    </div>}
                    {isAdmin && <div className='NavItem' onClick={() => this.setState({selectedIndex:3})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">assignment_ind</i>
                        <h6>Service-Staff</h6>
                    </div>}
                    <div className='NavItem' onClick={() => this.setState({selectedIndex:4})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">library_books</i>
                        <h6>Schedule</h6>
                    </div>
                    <div className='NavItem' onClick={() => this.setState({selectedIndex:5})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">queue</i>
                        <h6>Appointments</h6>
                    </div>
                    <div className='NavItem' onClick={() => this.setState({selectedIndex:6})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">announcement</i>
                        <h6>Pending</h6>
                        {this.state.pendingData.length !== 0 && <span className="new badge red">{this.state.pendingData.length}</span>}
                    </div>
                    {isAdmin && <div className='NavItem' onClick={() => this.setState({selectedIndex:7})}>
                        <i style={{marginRight:'2px'}} className="material-icons left">settings</i>
                        <h6>Settings</h6>
                    </div>}
                </div>
                <div className={pageWrapperClasses.join(' ')} style={{position:'relative'}}>
                    <div className={'PageContainer'}>
                        <div onClick={this.navBtnClickHandler} className='NavigationDrawer'><i style={{margin:'0px'}} className="material-icons left">{'chevron_'+ (this.state.navigationOpen ? 'left' : 'right')}</i></div>
                        {isAdmin && this.state.selectedIndex === 0 && <ServiceComponent />}
                        {isAdmin && this.state.selectedIndex === 1 && <StaffComponent />}
                        {isAdmin && this.state.selectedIndex === 2 && <LocationStaffComponent />}
                        {isAdmin && this.state.selectedIndex === 3 && <ServiceStaffComponent />}
                        {this.state.selectedIndex === 4 && <ScheduleComponent role={this.props.role} userId={localStorage.userId} />}
                        {this.state.selectedIndex === 5 && <AppointmentComponent statusValue={this.state.statusTracker} trackStatus={this.trackStatusChange} role={this.props.role} userId={localStorage.userId} hasPaypal={this.state.hasPaypal} currency={this.state.currency} refundFn={this.refundPayment} />}
                        {this.state.selectedIndex === 6 && <PendingAppointments isAdmin={this.props.role === 'Admin'} data={this.state.pendingData} changeStatus={this.pendingStatusChangeHandler} hasPaypal={this.state.hasPaypal} currency={this.state.currency} refundFn={this.refundPayment} refreshFn={this.getPendingAppointments} />}
                        {isAdmin && this.state.selectedIndex === 7 && <ThemeSelector currentTheme={this.state.theme} change={this.changeTheme} />}
                    </div>
                </div>   
                <button id="menuBtn" onClick={this.menuClickHandler} className={"btn-floating btn-large pulse "+(this.state.menuOpen?'MenuOpen': '')}><i className="material-icons">{this.state.menuOpen?'close':'menu'}</i></button>
                <div className={mobileMenuClasses.join(' ')}>
                    {isAdmin && <div onClick={() => this.setState({selectedIndex:0})} className={(this.state.selectedIndex===0?'active':'')} style={{marginLeft:this.getCurvature(1)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">phonelink_setup</i>
                        Services
                    </div>}
                    {isAdmin && <div onClick={() => this.setState({selectedIndex:1})} className={(this.state.selectedIndex===1?'active':'')} style={{marginLeft:this.getCurvature(2)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">person</i>
                        Staff-View
                    </div>}
                    {isAdmin && <div onClick={() => this.setState({selectedIndex:2})} className={(this.state.selectedIndex===2?'active':'')} style={{marginLeft:this.getCurvature(3)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">person_pin_circle</i>
                        Location-Staff
                    </div>}
                    {isAdmin && <div onClick={() => this.setState({selectedIndex:3})} className={(this.state.selectedIndex===3?'active':'')} style={{marginLeft:this.getCurvature(4)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">assignment_ind</i>
                        Service-Staff
                    </div>}
                    <div onClick={() => this.setState({selectedIndex:4})} className={(this.state.selectedIndex===4?'active':'')} style={{marginLeft:this.getCurvature(5)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">library_books</i>
                        Schedule Builder
                    </div>
                    <div onClick={() => this.setState({selectedIndex:5})} className={(this.state.selectedIndex===5?'active':'')} style={{marginLeft:this.getCurvature(6)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">queue</i>
                        Appointments
                    </div>
                    <div onClick={() => this.setState({selectedIndex:6})} className={(this.state.selectedIndex===6?'active':'')} style={{marginLeft:this.getCurvature(7)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">announcement</i>                       
                        Pending
                        {this.state.pendingData.length !== 0 && <span className="new badge red">{this.state.pendingData.length}</span>}
                    </div>
                    {isAdmin && <div onClick={() => this.setState({selectedIndex:7})} className={(this.state.selectedIndex===7?'active':'')} style={{marginLeft:this.getCurvature(8)}}>
                        <i style={{marginRight:'5px',color:'#fff'}} className="material-icons left">settings</i>
                        Settings
                    </div>}
                </div>            
            </div> :
            <div style={{margin:'10vh 0px',justifyContent:'center'}} className='InfoTip'>
                <i style={{color:'Orange',fontSize:'30px'}} className='material-icons'>report_problem</i>
                <span style={{fontSize:'20px'}}>You haven't registered for this service. Please <a onClick={this.sendActivationMail} href="#">click here</a> to activate.</span>
            </div>}
            {this.state.isLoading && <Loader />}
            </>
        )
    }
}

export default BreezeBook;