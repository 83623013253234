<div class="containerForTabsAndContent">
  <!-- Tab selection here -->

  <div class="analyticContent"></div>
  <div class="displayContainer">
    <div class="SectionsData">
      <div class="title">Lead Information</div>
      <div class="form">
        <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
          <div class="form1">

            <!-- Change Tabular to NG Materials -->
            <mat-card>
              <mat-tab-group class="space" (selectedTabChange)="tabChange($event)">
                <mat-tab
                  label="Review"
                  class="space"
                  *ngIf="reusable.data.review == '1'"
                >
                  <mat-radio-group
                    name="template"
                    aria-label="Select an option"
                    style="display: flex;flex-flow: column;justify-content: space-between;margin: 10px;gap:16px"
                  >
                    <ng-container *ngFor="let template of templatedata;let i=index" >
                      <mat-radio-button
                        *ngIf="template.account_id == 1"
                        value="{{ template.id }}"
                        (click)="getTemplateDataReview(template.id)"
                        #tempid
                        >{{ template.name }}</mat-radio-button
                      >
                  
                    </ng-container>
                  
                   
                  </mat-radio-group>
                </mat-tab>
                <mat-tab label="Alert" *ngIf="reusable.data.alert == '1'">
                  <mat-radio-group
                    name="template"
                    aria-label="Select an option"
                    
                    style="display: flex;flex-flow: column;justify-content: space-between;margin: 10px;gap:16px"
                  >
                    <ng-container *ngFor="let template of templatedata">
                      <mat-radio-button
                        *ngIf="template.account_id == 2"
                        value="{{ template.id }}"
                        (click)="getTemplateDataReview(template.id)"
                        >{{ template.name }}</mat-radio-button
                      >
                    </ng-container>
                  </mat-radio-group>
                </mat-tab>
              </mat-tab-group>
            </mat-card>

            <mat-form-field class="space">
              <input
                matInput
                placeholder="First Name"
                formControlName="name"
                [(ngModel)]="name"
              />
              <mat-error *ngIf="requestForm.get('name').touched">
                *Name is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="space">
              <input
                matInput
                placeholder="Number"
                id="number"
                value=""
                formControlName="number"
              />

              <mat-error *ngIf="requestForm.get('number').touched">
                *Number/Email is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="space">
              <input
                matInput
                placeholder="Email"
                id="email"
                type="email"
                value=""
                formControlName="email"
              />

              <mat-error *ngIf="requestForm.get('email').touched">
                *Number/Email is required
              </mat-error>
            </mat-form-field>

            <div class="row" *ngIf="isShown">
              <div class="col-md-6 col-sm-12">
                <mat-form-field class="space">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="Choose a date"
                    id="date"
                    formControlName="date"
                    (click)="picker.open()"
                    (keypress)="keyPress($event)"
                  />
                  <mat-datepicker #picker></mat-datepicker>

                  <mat-error *ngIf="requestForm.get('date').touched">
                    *date is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-sm-12">
                <mat-form-field class="space">
                  <input
                    matInput
                    [owlDateTimeTrigger]="dt4"
                    [owlDateTime]="dt4"
                    placeholder="Select Time"
                    (keypress)="keyPress($event)"
                    formControlName="time"
                  />
                  <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>

                  <mat-error *ngIf="requestForm.get('time').touched">
                    *Time is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="buttonFull space">
              <button id="Submit" type="submit">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="SectionsData phoneImg">
      <div
        style="position: absolute; padding: 8px; left: 210px; color: grey; background-color: white; height: 530px; width: 260px; border-radius: 0px 0 20px 20px; margin-top: 53px;"
      >
        Hi {{ name | checkLength: 15 }} <span *ngIf="name">,</span>
        <br />
        <br />
        <div class="PhoneContainer">
          <div *ngIf="temp_data_to_display.image">
            <img
              src="{{ temp_data_to_display.image }}"
              alt="logo"
              style="width: 100%;"
            />
          </div>
          <br />
          {{ temp_data_to_display.content }}
        </div>
        <br/>
        <br/>
        <div style="position: absolute; bottom:0px">
          <p><strong>Character count: {{temp_data_to_display.content.length}}</strong></p>
          <p><span style="color: red;">Warning:</span> Message with more than 160 characters will be split and sent as separate messages.</p>
        </div>
      </div>
      <div
        style="position: absolute; z-index: -1; left: 70px"
        class="phone"
      ></div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</div>
