import React from 'react'
import {cacheHeaders,basePath} from '../config'

import Appointment from './appointment'
import Loader from '../loader/loader'
import ExportToCSV from './ExportToCSV'

interface propsCompState {
    [key: string]: any
}

class Appointments extends React.Component<propsCompState,propsCompState>{

    state={
        appointments : [],
        isLoading: true
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    statusChangeHandler= (id, action) => {
        this.setState({isLoading: true});
        let apiKey = 'appointment/';
        let cancelStatus = 'Cancelled';
        if(!this.props.isAdmin){
            apiKey = 'staffAppointment/';
            cancelStatus = 'Pending';
        }
        let status='';
        if(action === 'done'){
            status='Completed';
        }
        else if(action === 'cancel'){
            status = cancelStatus;
        }
        let postData={
            status,
            _method: 'put'
        }
        fetch(basePath+apiKey+id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                'Content-Type': 'application/json',
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                let message = ''
                if(status === 'Completed')
                    message = 'Appointment has been marked as Completed';
                else if(status === 'Cancelled')
                    message = 'Appointment cancelled';
                else if(status === 'Pending')
                    message = 'Pending Approval for cancellation'
                this.toaster(true,message);
                this.props.track()
            }else{
                this.toaster(false,result.message);
            }
            this.setState({isLoading: false});       
        })
        .catch(err => {
            this.toaster(false,'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getAppointments = () => {
        this.setState({isLoading: true});
        let queryString = '?location_id='+localStorage.location_id+'&start_date='+this.props.startDate+'&end_date='+this.props.endDate+'&status='+this.props.status;
        let apiKey = 'appointment';
        if(!this.props.isAdmin){
            queryString = queryString+ '&staff_id='+ this.props.userId;
            apiKey = 'staffAppointment';
        }
        let url= basePath+apiKey+queryString;
        fetch(url,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({appointments: result.data, isLoading: false})
            }else{
                this.toaster(result.success,result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the '+this.props.status+' appointments could not be fetched!');
            this.setState({isLoading: false});
        });
    }

    sortBasedOnTime = (obj1,obj2) => {
        let startM = obj1.start_time.split(' ')[1];
        let startTime = Number(obj1.start_time.split(' ')[0].split(':').join(''));
        let endM = obj2.start_time.split(' ')[1];
        let endTime = Number(obj2.start_time.split(' ')[0].split(':').join(''));
        if(startM === endM){
            startTime = startTime < 1200 ? startTime+1200 : startTime;
            endTime = endTime < 1200 ? endTime+1200 : endTime;
            return startTime > endTime ? 1 : -1
        }else{
            return startM > endM ? 1 : -1
        }
    }

    sortBasedOnDate = (obj1,obj2) => {
        let date1 = new Date(obj1.date), date2 = new Date(obj2.date)
        if(date1.getTime() === date2.getTime())
            return this.sortBasedOnTime(obj1,obj2);
        else
            return date1 > date2 ? 1 : -1
    }

    componentDidMount(){
        this.getAppointments();
    }

    componentDidUpdate(prevProps, prevState){
        if((prevProps.statusTracker !== this.props.statusTracker) || (prevProps.startDate !== this.props.startDate) || (prevProps.endDate !== this.props.endDate)){
            this.getAppointments();
        }
    }

    render(){
        let appointments:any = <h6 style={{textAlign:'center'}}>Appointments not available! Try adjusting the filters.</h6>;
        if(this.state.appointments.length !== 0){
            appointments = [];
            let appointmentsArr = [...this.state.appointments].sort(this.sortBasedOnDate);
            for(let appointment of appointmentsArr){
                if(this.props.searchKey && ! (appointment.name.toLowerCase().includes(this.props.searchKey) || appointment.service_name.toLowerCase().includes(this.props.searchKey) || appointment.staff_name.toLowerCase().includes(this.props.searchKey)))
                continue;
                appointments.push(
                    <Appointment 
                    key={appointment.id} 
                    id={appointment.id}
                    date={appointment.date}
                    name={appointment.name}
                    number={appointment.number} 
                    email={appointment.email}
                    slot={appointment.start_time+' - '+appointment.end_time}
                    service={appointment.service_name}
                    staff={appointment.staff_name}
                    paypal={this.props.hasPaypal}
                    currency={this.props.currency}
                    paid={appointment.paid_amount}
                    refund={appointment.refund_amount}
                    actionRequired={this.props.status === 'Confirmed'}
                    isAdmin={this.props.isAdmin}
                    doneName='Done'
                    cancelName='Cancel'
                    doneFn = {() => this.statusChangeHandler(appointment.id,'done')}
                    cancelFn = {() => this.statusChangeHandler(appointment.id,'cancel')}
                    refundFn={this.props.refund}
                    refresh = {this.getAppointments}
                     />
                );
            }
        }

        return (
            <>
            <div id={this.props.id} className='Appointments'>
                {this.state.appointments.length !== 0 && 
                    <div style={{display:'flex',width:'100%',justifyContent:'flex-end',gap:'10px'}}>
                        <ExportToCSV csvData={this.state.appointments} fileName={this.props.status+"-Appointments.csv"} />
                        <span style={{fontSize:'15px',fontWeight:'bold',alignSelf:'flex-end'}}>{'Showing '+appointments.length+' of '+this.state.appointments.length+' appointments'}</span>
                    </div>}
                {appointments}
            </div>
            { this.state.isLoading && <Loader />}
            </>
        )
    }
}

export default Appointments;