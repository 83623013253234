<app-header></app-header>
<div class="home-container">
  <div class="containerForTabsAndContent col-sm-12">
   
    <!-- Tab selection here -->
    <div class="tabs"></div>
   
    <mat-tab-group >
    
      <mat-tab label="Filter Landing Page Setting" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager') && (reuse.data.alert == '1' || reuse.data.review == '1' || reuse.data.back == '1')">
          <div class="acWrapper">
              <app-rating-landing-page-settings></app-rating-landing-page-settings>
       
          </div>
      </mat-tab>
      <mat-tab label="Templates" *ngIf="(reuse.data.role === 'Admin' || reuse.data.role === 'Manager')">
      
        <app-templates></app-templates>
      </mat-tab>
      <mat-tab label="Profile">
       
        <app-profile></app-profile>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
