import { Component, OnInit } from '@angular/core';
import { ExtraServiceService } from "../../extra-service.service"
import { ReusableService } from "../../../reusable.service";
import {Subject} from 'rxjs';

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.css"]
})
export class TemplatesComponent implements OnInit {

  alertchild ;
  reviewchild;
  backreviewchild ;
  backcouponchild ;
  backvipchild ;

  constructor(private data: ExtraServiceService,public reusable: ReusableService) { }
alertdata=[];
reviewdata=[];
backreviewdata=[];
backcoupondata=[];
backvipdata=[];

  ngOnInit() { 
    this.gettemplates()
  }


  gettemplates(){
   // console.log(localStorage.location_id);
    this.reusable.showspinner();
    if(localStorage.location_id == '')
    {
      this.gettemplates();
    }
   else{
    this.data.gettemplates(localStorage.location_id).subscribe(
      result => {
        // console.log(result);
        if (result.success == true) {  
          for(let data of result.data)
          {
            if(data.account_id == 2){
              this.alertdata.push(data)
            }
            else if(data.account_id == 1){
              this.reviewdata.push(data)
            }
            else if(data.account_id == 3){
              this.backreviewdata.push(data)
            }
            else if(data.account_id == 4){
              this.backcoupondata.push(data)
            }
            else if(data.account_id == 5){
              this.backvipdata.push(data)
            }
          }

          this.alertchild = this.alertdata;
          this.reviewchild = this.reviewdata;         
          this.backreviewchild = this.backreviewdata;
          this.backcouponchild = this.backcoupondata;
          this.backvipchild = this.backvipdata;


          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
   }

  }
  


}
