import React from 'react'

import LocStaff from './locStaff'
import {cacheHeaders,basePath} from '../config'
import M from 'materialize-css';

import Loader from '../loader/loader'

interface compState {
    [key: string]: any
}

class LocationStaffComponent extends React.PureComponent<{},compState>{

    state={
        availableStaff:[],
        remainingStaff:[],
        assignedStaff:[],
        isLoading: true
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    addStaffToLocation = (id) => {
        this.setState({isLoading: true});
        let postData ={
            staff_id: id,
            location_id: localStorage.location_id
        }
        fetch(basePath+'staffLocation',{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            this.toaster(result.success, result.message);
            if(result.success){
                this.getAssignedStaff([...this.state.availableStaff]);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    removeStaffFromLocation = (id) => {
        this.setState({isLoading: true});
        fetch(basePath+'staffLocation/'+id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'delete'
        })
        .then(res => res.ok ? res.json() : Promise.reject())
        .then(result => {
            this.toaster(result.success,result.message);
            result.success && this.getAssignedStaff([...this.state.availableStaff]);
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getAssignedStaff = (availableStaff) => {
        this.setState({isLoading: true});
        fetch(basePath+'staffLocation?location_id='+localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                let assignedStaff = [...result.data];
                let remainingStaff = availableStaff.filter(availStaff => {
                    for(let staff of assignedStaff){
                        if(staff.staff_id === availStaff.id){
                            return false;
                        }
                    }
                    return true;
                });
                this.setState({availableStaff:availableStaff,remainingStaff: remainingStaff,assignedStaff: assignedStaff});
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        }) 
    }

    componentDidMount(){
        M.AutoInit();
        this.setState({isLoading: true});
        fetch(basePath+'staff',{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.getAssignedStaff(result.data);
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    render(){
        return (
            <>
                <div className='ActionDiv'>
                    <h4>Staff - Location Mapping</h4>
                </div>
                <div className='InfoTip'>
                    <i style={{color:'#4CAF50'}} className={"material-icons"}>info</i>
                    <span>Click on a Staff to assign or remove them from the location</span>
                </div> 
                <div className='Mapper'>
                    <div className='LeftColumn'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>Available Staff</h5>
                        {
                            this.state.remainingStaff.length === 0 ? <h6>Staff Not Available</h6> :
                            this.state.remainingStaff.map(staff => (
                                <LocStaff key={staff.id} clicked={() => this.addStaffToLocation(staff.id)} name={staff.first_name +' '+ staff.last_name} email={staff.email} dir='left' />
                            ))
                        }
                    </div>
                    <div className='RightColumn'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>Assigned Staff</h5>
                        {
                            this.state.assignedStaff.length === 0 ? <h6>Staff Not Available</h6> :
                            this.state.assignedStaff.map(staff => (
                                <LocStaff key={staff.id} clicked={() => this.removeStaffFromLocation(staff.id)} name={staff.first_name +' '+ staff.last_name} email={staff.email} dir='right' />
                            ))
                        }
                    </div>
                </div>
                {this.state.isLoading && <Loader />}
            </>
        )
    }
}

export default LocationStaffComponent;