import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import {ExtraServiceService} from '../../extra-service.service'
import { ReusableService } from "../../../reusable.service";
import { AppServiceService } from "../../../app-service.service";
import { from } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  selectedFile: any = null;
submitted = false;
profileform1: FormGroup;
public imagePath;
imgURL: any;

  constructor( private router: Router,
    private data: ExtraServiceService,
    public reusable: ReusableService,
    public headerservice: AppServiceService,
    public fb: FormBuilder
    ) { }
fname;
lname
email;
username;

  ngOnInit() {
 
  
               this.fname = localStorage.fname
              this.lname  = localStorage.lname
              this.email = localStorage.email 
              this.username = localStorage.username
              this.profileform1 = this.fb.group({
                first_name: [this.fname, Validators.required],
                last_name: [this.lname,Validators.required],
                password: [""],
                image: [""]
              });
          
  }

  

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.profileform1.get("image").setValue(this.selectedFile);

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.imagePath = event.target.files;
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgURL = reader.result; //add source to image
      }
    }
   //console.log(this.selectedFile);
  }

  onSubmit1(nothing) {
    this.reusable.showspinner();
    this.submitted = true;
   
   const fd = new FormData();
   fd.append("image", this.profileform1.get("image").value);
   fd.append("first_name", this.profileform1.get("first_name").value);
   fd.append("last_name", this.profileform1.get("last_name").value);
   fd.append("password", this.profileform1.get("password").value);
   
  //  if(this.profileform1.get("image").value || this.profileform1.get("first_name").value || this.profileform1.get("last_name").value || this.profileform1.get("password").value){
  
    this.data.Profile(fd).subscribe(
      result => {
        // console.log(result);
        if (result) {
         // console.log(result);
          if (result.success == true) {
            // console.log(result);
            localStorage.fname = this.profileform1.get("first_name").value;
            localStorage.lname = this.profileform1.get("last_name").value;
             localStorage.name = this.profileform1.get("first_name").value + " "+ this.profileform1.get("last_name").value;
             if(this.profileform1.get("image").value != ""){
              localStorage.image = this.imgURL;
             }
             
            this.reusable.hidespinner();
            window.location.reload()
           
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "Error", "red");
          }
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }
  // else{
  //   this.reusable.openSnackBar(
  //     "Nothing on the form has been changed ",
  //     "Error",
  //     "red"
  //   );
  // }
  }
