import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthServiceService } from "../auth-service.service";
import { ReusableService } from "../../reusable.service";
import { AppServiceService } from "../../app-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @Input() productData = { email: "", password: "" };
  LoginForm: FormGroup;
  submitted = false;
  email = "";

  constructor(
    private router: Router,
    private data: AuthServiceService,
    public reusable: ReusableService,
    public headerservice: AppServiceService
  ) {}


  login(email, password) {
    this.reusable.showspinner();
    this.submitted = true;
    this.productData.password = password;
    this.productData.email = email;

    this.data.login(this.productData).subscribe(
      result => {
        // console.log(result);
        if (result) {
         // console.log(result);
          if (result.success == true) {
            // console.log(result);
            localStorage.token = result.data.token;
            localStorage.name = result.data.first_name +" "+ result.data.last_name;
            localStorage.fname =  result.data.first_name;
            localStorage.lname = result.data.last_name;
            localStorage.role = result.data.role;
            localStorage.image = result.data.image;
            localStorage.username = result.data.username;
            localStorage.email = email;
            localStorage.userId = result.data.user_id;
            localStorage.compName = result.data.company_name;

            // Setting Role in the ReUsable service
            this.reusable.data.role = result.data.role;

            this.headerservice.setheader();
            this.reusable.hidespinner();
            this.router.navigate(["home"]);
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "Error", "red");
          }
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
    //localStorage.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjAxMTQ1YWM4ODY2NzkwNzAwNDQwZjAyNTYwNjFiMDg0YmM0NDRiNzAyYzVkMTA0YzM1OWY2YzUyYmU2NTY5YTY3YjE4MzU4ZjgyOGMxZGE2In0.eyJhdWQiOiIxIiwianRpIjoiMDExNDVhYzg4NjY3OTA3MDA0NDBmMDI1NjA2MWIwODRiYzQ0NGI3MDJjNWQxMDRjMzU5ZjZjNTJiZTY1NjlhNjdiMTgzNThmODI4YzFkYTYiLCJpYXQiOjE1Nzc3Mzc1MzgsIm5iZiI6MTU3NzczNzUzOCwiZXhwIjoxNjA5MzU5OTM4LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.sgE6rEo9ARBOsjA9_G5-6XhpUgYRuGLrgGdf4elaKctyz7nZvkStSZ8vEaH7mVS8ptKHBNJrLjDaG9teuc5vL7KwGJkrmDV0QSeUhhopurdGN-51mVCSlBTGemhDxrVPJ6wXtFKzHR-3n8boKhbJ2dgceNaqIYxN3tRFERIeKg1KcE_o28VElzX8pYWWz5obTALaC4T3vmZX-VQfnhld1nt5wOlBPueTI83d0N5h_ph5daT97YcxSm_ctA12bw1me2tC4tf5GIfskwuvurizzedtr83uzXEgrbA9GSrIyB9XMoDhq7fgYWrX-JCkdxoymYNL4kUQyvHZ1Fi6dkvNAozezpmsK981SA7XV47hgWfhlCnDVD9m0IxSvUBTdB9KxamCeZbWoVxC50mIvSApewmV8U5PGKw4JyeWUuk5PF2SCUPwAnUrtZdDQ7hWmGp2uwAUkFL-MN7u01ruJ-cKr0og7cscX-ydl9gDbv9s1CkUCmD6aeK7r7iSOSgJQ421TiHGwcjrTuttG8WjqN70KZQ4CEk-baFjMntKDAO-IWEf0NeoUyS6IIXZ9x1c9z_lbP8LfbbmZ8lriOcHhHKxvwNKFw-KCAaqEcMb6v7_dWvV645MtCAVyeKldPXHo5wxv8LyeV4I352CFPeNX6IQztNv9vCzPfGO6HF-OljJMTM";
    this.router.navigate(["home"]);
  }

  ngOnInit() {
    this.setupvalidation()
    
  }

  setupvalidation(){
    this.LoginForm = new FormGroup({
      'email': new FormControl(null,[Validators.required,Validators.email]),
      'password': new FormControl(null,[Validators.required])
    });
  }

  onSubmit(test){
    // console.log(this.LoginForm.value.email)
    this.login(this.LoginForm.value.email,this.LoginForm.value.password)
  }
}
