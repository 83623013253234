import React from 'react'

const ScheduleDay = (props) => {

    const sortTimeSlots = (obj1,obj2) => {
        let startM = obj1.start_time.split(' ')[1];
        let startTime = Number(obj1.start_time.split(' ')[0].split(':').join(''));
        let endM = obj2.start_time.split(' ')[1];
        let endTime = Number(obj2.start_time.split(' ')[0].split(':').join(''));
        if(startM === endM){
            startTime = startTime < 1200 ? startTime+1200 : startTime;
            endTime = endTime < 1200 ? endTime+1200 : endTime;
            return startTime > endTime ? 1 : -1
        }else{
            return startM > endM ? 1 : -1
        }
    }

    return (
        <div className='card ScheduleDay'>
            <div className='ScheduleDayContent'>
                {props.day+' - '+props.date}
                {props.actionEnabled && <button style={{color:'#20232a',padding:'0px'}} className='btn btn-flat ActionBtn'>
                    <i onClick={props.addClick} className="material-icons">add</i>
                </button>}
            </div>
            <div className='SlotDiv'>
                {
                    props.slots ? props.slots.sort(sortTimeSlots).map(slot => (
                        <div key={slot.id} className='chip slot'>
                            <b>{slot.start_time+' - '+slot.end_time}</b>                          
                            {props.actionEnabled &&<button style={{color:'#20232a',padding:'0px'}} className='btn btn-flat ActionBtn'>
                                <i onClick={() => props.closeClick(slot.id)} className="material-icons">close</i>
                            </button>}
                        </div>
                    )) : null
                }
            </div>
        </div>
    )
}

export default ScheduleDay;