<div class="card">
  <div class="card-header">
   
  </div>
  <mat-card>
    <mat-toolbar>
  
        <span>BackCoupon Templates</span>
      </mat-toolbar>
    <mat-list *ngFor="let coupontemp of parentdata">
      <mat-list-item>
        <div class="row col-md-6 justify-content-center">
          <div class="col-md-8">
            {{ coupontemp.name }}
          </div>
          <div class="col-md-4 p5">
            <!-- <mat-icon
              aria-hidden="false"
              aria-label="Example remove icon"
              (click)="delete(coupontemp)"
              style="font-size:20px;color:darkcyan;cursor: pointer;margin-right: 20%;"
              >delete</mat-icon
            > -->
            <mat-icon
              aria-hidden="false"
              aria-label="Example edit icon"
              (click)="openDialog(coupontemp)"
              style="font-size:20px;color:darkcyan;cursor: pointer;"
              >edit</mat-icon
            >
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
