import React from 'react'

const LocStaff = ({name,email,dir,clicked}) => {
    return(
        <div className='LocStaff' onClick={clicked}>
            {dir === 'right' && <i style={{color:'#F44336',position:'absolute', left: '5px'}} className={"material-icons"}>arrow_back</i>}
            <p style={{fontSize:'16px',margin:'0px',width:'90%'}}><b>{name}</b> | {email}</p>
            {dir === 'left' && <i style={{color:'#4CAF50',position:'absolute', right: '5px'}} className={"material-icons"}>arrow_forward</i>}
        </div>
    )
}

export default LocStaff;