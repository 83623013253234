<mat-accordion>
    <mat-expansion-panel class="container" *ngIf="reusable.data.review == '1'">
      <mat-expansion-panel-header>
        <mat-panel-title>
         Review Template
        </mat-panel-title>
        <mat-panel-description>
            
        </mat-panel-description>
      </mat-expansion-panel-header>

 <div><app-review-temp [notifier]="reviewchild"></app-review-temp></div> 
    </mat-expansion-panel>

    <mat-expansion-panel class="container" *ngIf="reusable.data.alert == '1'">
      <mat-expansion-panel-header>
        <mat-panel-title>
            Alert Template
        </mat-panel-title>
        <mat-panel-description>
         
        </mat-panel-description>
      </mat-expansion-panel-header>
     <app-alert-temp [notifier]="alertchild" ></app-alert-temp>
    </mat-expansion-panel>

    <mat-expansion-panel class="container" *ngIf="reusable.data.back == '1'" >
      <mat-expansion-panel-header>
        <mat-panel-title>
            BackReview Template
        </mat-panel-title>
        <mat-panel-description>
          
        </mat-panel-description>
      </mat-expansion-panel-header>
     <app-back-temp [notifier]="backreviewchild"></app-back-temp>
    </mat-expansion-panel>

    <mat-expansion-panel class="container" *ngIf="reusable.data.back == '1'" >
      <mat-expansion-panel-header>
        <mat-panel-title>
            BackCoupon Template
        </mat-panel-title>
        <mat-panel-description>
          
        </mat-panel-description>
      </mat-expansion-panel-header>
     <app-backCoupon-temp [notifier]="backcouponchild"></app-backCoupon-temp>
    </mat-expansion-panel>

    <mat-expansion-panel class="container" *ngIf="reusable.data.back == '1'" >
      <mat-expansion-panel-header>
        <mat-panel-title>
            BackVIP Template
        </mat-panel-title>
        <mat-panel-description>
          
        </mat-panel-description>
      </mat-expansion-panel-header>
     <app-backVip-temp [notifier]="backvipchild"></app-backVip-temp>
    </mat-expansion-panel>


  </mat-accordion>