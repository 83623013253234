import { Component, OnInit, Input } from '@angular/core';
import { ReviewServiceService } from "../review-service.service";
import { ReusableService } from "../../reusable.service";
import {Subject} from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})

export class OverviewComponent implements OnInit {

  @Input() dashboard;
  @Input() active;
  
  total_request_review="";
  pending_request_review="";
  this_month_request_review="";
  target_review = "";

  current_delivered="";
  current_cancelled="";
  current_rescheduled="";
  target_alert = "";
  clicked=false;


  constructor(private data: ReviewServiceService,public reusable: ReusableService) { }

    childNotifier : Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.getalldata();
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async  getalldata (){
    this.reusable.showspinner();
if(localStorage.location_id == "")
{
   await this.delay(3000);
}
    this.data.getoverview(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {
          this.total_request_review = result.data.review.current_request;
          this.pending_request_review = result.data.review.current_pending;
          this.this_month_request_review = result.data.review.current_completed;
          this.target_review = result.data.review.target;

          this.current_delivered = result.data.alert.current_delivered;
          this.current_cancelled = result.data.alert.current_cancelled;
          this.current_rescheduled = result.data.alert.current_rescheduled;
         // this.target_alert = result.data.alert.target;



          
          this.reusable.graphdata = result;
          this.childNotifier.next(result);

          this.reusable.hidespinner();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );

  }
  
  activateAccount(ev){
    ev.preventDefault();
    let account: string;
    if(this.dashboard === 'review')
      account = 'BreezeReview'
    if(this.dashboard === 'alert')
      account = 'BreezeAlert'
    this.reusable.sendActivationMail(account);
  }

}
