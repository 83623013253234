
// declare all the global variable here for the service of all the other service file.

import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  httpHeaderOptions: any;
  httpHeaderOptions2: any;
  // APIEndPoint="https://api.breezereview.com/api/"; //Decory API

  APIEndPoint="https://apil.breezereview.com/api/"; // Muhammads API

  constructor(public jwtHelper: JwtHelperService) { 
    this.setheader();
  }

  // Decory API header
  // setheader() {
  //   this.httpHeaderOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       "x-access-token" : localStorage.token
  //     })
  //   };
  // }

// Muhammad API Header
  setheader() {
    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.token
      })
    };
  }

  loginheader() {
    this.httpHeaderOptions2 = {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data, application/json",
        Authorization: "Bearer " + localStorage.token
      })
    };
  }

  public isAuthenticated(): boolean {

    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(localStorage.token);
  }


}
