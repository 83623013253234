import React from 'react';

import {cacheHeaders,basePath} from '../config'
import M from 'materialize-css';
import Loader from '../loader/loader'

interface compState {
    [key: string]: any
}

class ServiceComponent extends React.PureComponent<{},compState>{

    initialFormData = {
        id : 0,
        name : '',
        description : '',
        duration_min: '',
        cost: 0,
        paymentRequired: false,
        bookingAmount: 0,
        refundAmount: 0,
        serviceFeeName: '',
        serviceFeeAmount: 0,
        location_id: localStorage.location_id
    }
    
    state={
        isLoading: true,
        services : [],
        formOpen : false,
        formType: 'Add',
        formData : this.initialFormData,
        delElement: {
            id : 0,
            name: ''
        },
        hasPaypal: false,
        paymentEnabled: false,
        currency: 'CAD'
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

   formOpenHandler = () => {
        if(this.state.formOpen)
            (document.getElementById('serviceForm') as HTMLFormElement).reset();
        this.setState((prevState) => {
            return {formOpen : !prevState.formOpen,formType: 'Add', formData : this.initialFormData}
        });
    }

    inputChangeHandler = (event) => {
        let value = event.target.value;
        if(event.target.id.includes('Percent')){
            value = this.state.formData.cost * value/100;
        }else if(event.target.type === 'checkbox'){
            value = event.target.checked;
        }
        let formData = {...this.state.formData};
        formData[event.target.name] = value;
        this.setState({
            formData : formData
        })
    }

    paymentToggleHandler = () => {
        this.setState({isLoading: true});
        fetch(basePath+'updatePaymentEnable?location_id='+ localStorage.location_id+'&payment_enable='+(+!this.state.paymentEnabled),{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.getPaymentStatus();
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })

    }

    editHandler = (service) => {
        (document.getElementById('serviceForm') as HTMLFormElement).reset();
        this.setState((prevState) => {
            return {formOpen : !prevState.formOpen,formType: 'Edit', formData : service}
        });
    }

    deleteHandler = () => {
        this.setState({isLoading: true});
        fetch(basePath+'service/'+this.state.delElement.id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'delete'
        })
        .then(res => res.ok ? res.json() : Promise.reject())
        .then(result => {
            this.toaster(result.success,result.message);
            result.success && this.getServiceList();
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getServiceList = () => {
        this.setState({isLoading: true});
        fetch(basePath+'service?location_id='+ localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({services: result.data});
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    submitHandler = (event) => {
        this.setState({isLoading: true});
        event.preventDefault();
        let url = basePath + 'service';
        let postData ={}
        if(this.state.formData.id !== 0){
            url = url + '/' + this.state.formData.id;
            postData = {...this.state.formData, _method : 'put'}
        }else{
            postData = {...this.state.formData}
        }
        fetch(url,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            this.toaster(result.success, result.message);
            if(result.success){
                this.formOpenHandler();
                this.getServiceList();
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getPaymentStatus = () => {
        this.setState({isLoading: true});
        fetch(basePath+'locationPaymentInfo?location_id='+ localStorage.location_id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({hasPaypal: result.data.hasPaypal, paymentEnabled: result.data.hasPaypal && result.data.payment_enable,currency:result.data.currency,isLoading: false});
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    componentDidMount(){
        M.AutoInit();
        this.getServiceList();
        // For getting payment status
        this.getPaymentStatus();
    }

    render(){
        return(
            <>
                <div className='ActionDiv'>
                    <h4>Available Services</h4>
                    <button onClick={this.formOpenHandler} className='btn-flat waves-effect waves-light ActionBtn' type='button' >
                        <i style={{marginRight:'5px'}} className="material-icons left">add</i>
                        Add Service
                    </button>
                </div>
                <div className='InfoTip'>
                    <i style={{color:'#4CAF50'}} className={"material-icons"}>info</i>
                    <span>Add or Modify the services offered in this location</span>
                </div>
                {
                    this.state.hasPaypal ?
                    <div className="switch PaymentToggle card">
                        <label style={{fontSize:'15px', margin:'0px'}}>
                        {'Payment '+ (this.state.paymentEnabled? 'Enabled':'Disabled')}
                        <input id="payment" checked={this.state.paymentEnabled} onChange={this.paymentToggleHandler} name="payment" type="checkbox" />
                        <span className="lever"></span>
                        </label>
                    </div> : null
                }
                <div className='EntityContainer'>
                {
                    this.state.services.length === 0 ? <h5>No Services available at the moment</h5> : 
                    this.state.services.map(service => (
                        <div key={service.id} className='Service'>
                            <div>
                                <h6 style={{color:'var(--secondary-font-color)',fontWeight:'bold'}}>{service.name}</h6>
                                <p>{ service.description }</p>
                                <p><b>{service.duration_min + ' mins | $ ' + (+service.cost ? service.cost : '')}</b></p>
                            </div>
                            <div className='ServiceAction'>
                                <button style={{color:'#4CAF50'}} className='btn ActionBtn' onClick={() => this.editHandler({...service})} type='button' >Edit</button>
                                <button style={{color:'#F44336'}} className='btn ActionBtn modal-trigger' data-target="delete_modal" onClick={() => this.setState({delElement:{...service}})} type='button' >Delete</button>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className={'FormContainer card-panel' + (this.state.formOpen ?' FormOpen' : '')}>
                    <div className='FormHeader'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>{this.state.formType + ' Service'}</h5>
                        <i style={{cursor:'pointer'}} onClick={this.formOpenHandler} className="material-icons">close</i>
                    </div>
                    <form onSubmit={this.submitHandler} id='serviceForm' style={{width:'100%',height:'80%',overflowY:'auto',overflowX:'hidden'}}>
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="name" name='name' value={this.state.formData.name} onChange={this.inputChangeHandler} type="text" required autoComplete='off' className="validate" />
                                <label className={this.state.formData.name !== '' ? 'active' : ''} htmlFor="name">Service Name</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="description" name='description' value={this.state.formData.description} onChange={this.inputChangeHandler} type="text" autoComplete='off' className="validate" />
                                <label className={this.state.formData.description !== '' ? 'active' : ''} htmlFor="description">Description</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="duration_min" name='duration_min' value={this.state.formData.duration_min} onChange={this.inputChangeHandler} type="number" min='15' required autoComplete='off' className="validate" />
                                <label className={this.state.formData.duration_min !== '' ? 'active' : ''} htmlFor="duration_min">Duration (in mins)</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="cost" name='cost' value={this.state.formData.cost} step="any" onChange={this.inputChangeHandler} type="number" min='0' required autoComplete='off' className="validate" />
                                <label className='LabelActive' htmlFor="cost">{'Cost ('+this.state.currency+')'}</label>
                            </div>
                            {
                                (!!this.state.paymentEnabled && 
                                <>
                                <div className="input-field col s12">
                                    <div className="switch">
                                        <label style={{fontSize:'15px'}}>
                                        {'Charge Payment for Booking'}
                                        <input id="paymentRequired" checked={this.state.formData.paymentRequired} onChange={this.inputChangeHandler} name="paymentRequired" type="checkbox" />
                                        <span className="lever"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    (!!this.state.formData.paymentRequired && 
                                    <div className="input-field col s12">                            
                                        <div className="row">
                                            <p>Booking Amount</p>
                                            <div className="input-field col s6">
                                                <input id="bookingAmountPercent" name='bookingAmount' value={((this.state.formData.bookingAmount/this.state.formData.cost)*100).toFixed(2)} step="any" onChange={this.inputChangeHandler} type="number" min='0' max='100' required autoComplete='off' className="validate" />
                                                <label className='LabelActive' htmlFor="bookingAmountPercent">Percent</label>
                                            </div>
                                            <div className="input-field col s6">
                                                <input id="bookingAmountWhole" name='bookingAmount' disabled={this.state.formData.bookingAmount >= (+this.state.formData.cost + .01)} value={Number(this.state.formData.bookingAmount).toFixed(2)} step="any" onChange={this.inputChangeHandler} type="number" min='0' required autoComplete='off' className="validate" />
                                                <label className='LabelActive' htmlFor="bookingAmountWhole">{'Currency ('+this.state.currency+')'}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <p>Refund Amount</p>
                                            <div className="input-field col s6">
                                                <input id="refundAmountPercent" name='refundAmount' value={((this.state.formData.refundAmount/this.state.formData.cost)*100).toFixed(2)} step="any" onChange={this.inputChangeHandler} type="number" min='0' max='100' required autoComplete='off' className="validate" />
                                                <label className='LabelActive' htmlFor="refundAmountPercent">Percent</label>
                                            </div>
                                            <div className="input-field col s6">
                                                <input id="refundAmountWhole" name='refundAmount' disabled={this.state.formData.refundAmount >= (+this.state.formData.cost+ .01)} value={Number(this.state.formData.refundAmount).toFixed(2)} step="any" onChange={this.inputChangeHandler} type="number" min='0' required autoComplete='off' className="validate" />
                                                <label className='LabelActive' htmlFor="refundAmountWhole">{'Currency ('+this.state.currency+')'}</label>
                                            </div>
                                        </div>
                                    </div>)
                                }
                                </>)
                            }
                        </div>
                        <div className='FormAction'>
                            <button style={{color:'#4CAF50'}} className='btn ActionBtn' type='submit' >Save</button>
                            <button style={{color:'#F44336'}} onClick={this.formOpenHandler} className='btn ActionBtn' type='button' >Cancel</button>
                        </div>
                    </form>
                </div>
                {this.state.formOpen && <div onClick={this.formOpenHandler} className='BackDrop'></div>}
                <div id="delete_modal" className="modal">
                    <div className="modal-content">
                    <h4 style={{color:'var(--secondary-font-color)'}}>Delete Service</h4>
                    <h6>Are you sure you want to delete this service?<br/><br/>
                        <b>{this.state.delElement.name}</b>
                    </h6>
                        <div className="modal-footer">
                            <button style={{color:'#4CAF50'}} className="btn ActionBtn modal-close" onClick={this.deleteHandler}>Yes</button>
                            <button style={{color:'#F44336'}} className="btn ActionBtn modal-close">No</button>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <Loader />}
            </>
        )
    }
    
}

export default ServiceComponent;