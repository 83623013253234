import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { ReusableService } from 'src/modules/reusable.service';
import { AuthServiceService } from '../auth-service.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-businessReview',
  templateUrl: './businessReview.component.html',
  styleUrls: ['./businessReview.component.css']
})
export class BusinessReviewComponent implements OnInit {

  routerParams;

  constructor(private route: ActivatedRoute,public reusable: ReusableService,
    private data: AuthServiceService, public fb: FormBuilder, private router: Router,) { 
      this.routerParams = this.route.params;
  }

  negativeform: FormGroup;
  positiveform: FormGroup;

  number;
  btncolor = "#83aff8";
  bottom_text = "Enter your details and please wait while we redirect you to leave your written review. Thank You in Advance!";

  public link;
  public color = "#fff";
  public imgURL: any = "assets/breezereviewlogo.png";
  public message: string = " It was a pleasure assisting you. Please tell us how we did!";
  public submitted = false;

  ngOnInit() {
    this.setupvalidation();
    this.getLandingReview()
  }

  setupvalidation(){
    this.negativeform = new FormGroup({ 
      'reason': new FormControl('',[Validators.required]),
      'name': new FormControl('',[Validators.required]),
      'number': new FormControl('',[Validators.pattern('^([+]?[0-9]{1,2})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$'),Validators.required]),
      'email': new FormControl('',[Validators.email])
    });
    this.positiveform = new FormGroup({ 
      'name': new FormControl('',[Validators.required]),
      'number': new FormControl('',[Validators.pattern('^([+]?[0-9]{1,2})?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$'),Validators.required]),
      'email': new FormControl('',[Validators.email])
    });
  }

  check(number){
   this.number = number;
  }

  getLandingReview() {
    this.reusable.showspinner();

    this.data.getBusinessReviewLanding(this.routerParams.value.location,this.routerParams.value.social)
    .subscribe(result => {
      if(result.success){
        this.imgURL = result.data.image;
        this.message = result.data.message;
        this.color = result.data.bgcolor;
        this.link = result.data.url;
      }else{
        this.reusable.openSnackBar(result.message, "Error", "red");
      }
      this.reusable.hidespinner();
    },
    err => {
      this.reusable.hidespinner();
      this.reusable.openSnackBar(
        "  Cannot connect to the Portal. Please log out and log back in",
        "Error",
        "red"
      );
    })
  }

  getPostData(type: string) : FormData{
    const fd = new FormData();
    if(type === 'positive'){
      fd.append("location_id", this.routerParams.value.location);
      fd.append("social_id", this.routerParams.value.social);
      fd.append("first_name", this.positiveform.get("name").value);
      fd.append("number", this.positiveform.get("number").value);
      fd.append("rate", this.number);
      if(this.positiveform.get("email").value !== ""){
        fd.append("email", this.positiveform.get("email").value);
      }
    }else if(type === 'negative'){
      fd.append("location_id", this.routerParams.value.location);
      fd.append("social_id", this.routerParams.value.social);
      fd.append("first_name", this.negativeform.get("name").value);
      fd.append("number", this.negativeform.get("number").value);
      fd.append("message", this.negativeform.get("reason").value);
      fd.append("rate", this.number);
      if(this.negativeform.get("email").value !== ""){
        fd.append("email", this.negativeform.get("email").value);
      }
    }
    return fd;
  }

  onPositiveReview(){
    this.reusable.showspinner();
    const data = this.getPostData('positive');
    this.data.postBusinessReview(data).subscribe(result => {
      if(result.success){
        this.submitted = true;
      }else{
        this.reusable.openSnackBar(result.message, "Error", "red");
      }
      this.reusable.hidespinner();
    },
    err => {
      this.reusable.hidespinner();
      this.reusable.openSnackBar(
        "  Cannot connect to the Portal. Please log out and log back in",
        "Error",
        "red"
      );
    })
  }

  onNegativeReview(){
    this.reusable.showspinner();
    const data = this.getPostData('negative');
    this.data.postBusinessReview(data).subscribe(result => {
      if(result.success){
        this.submitted = true;
      }else{
        this.reusable.openSnackBar(result.message, "Error", "red");
      }
      this.reusable.hidespinner();
    },
    err => {
      this.reusable.hidespinner();
      this.reusable.openSnackBar(
        "  Cannot connect to the Portal. Please log out and log back in",
        "Error",
        "red"
      );
    })
  }
}
