import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { ReusableService } from 'src/modules/reusable.service';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  @Input() type : string;
  @Input() active : boolean;
   background = '';
  colors = '';
  constructor( public reuse: ReusableService,) { }

  activateBreezeBack(ev){
    ev.preventDefault();
    this.reuse.sendActivationMail('BreezeBack');
  }

  ngOnInit() {
  
    this.background = 'lightskyblue';
    this.colors = 'white';
  }

 
}

