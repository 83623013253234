<div class="LoginContainer">
  <div class="item one">
    <form  [formGroup]="LoginForm" (ngSubmit)="onSubmit(LoginForm)">
    <div class="contentwrapper">
     
      <div class="logo">
        <img class="imagelogo" src="assets/Logo.png" alt="" />
      </div>
      <div class="title" style="padding: 10px;">Take Control of Your Reputation Before Others Do</div>
     
      <div class="form col-sm-12">
        
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            #username
            formControlName="email"
          />
          
      
          <mat-error *ngIf="LoginForm.get('email').invalid">
            Please enter a valid email address
          </mat-error>
           <mat-error *ngIf="LoginForm.get('email').touched">
            Email is required
          </mat-error>
          <br>
        </mat-form-field>

        <mat-form-field style="margin-top: 10px;" class="input">
          <input
            matInput
            placeholder="Password"
            id="password"
            value=""
            type="password"
            #password
            formControlName="password"
          />
          <mat-error *ngIf="LoginForm.get('password').invalid">
            Password required
          </mat-error>
        </mat-form-field>
      </div>
    
      <div class="controls col-sm-12" style="padding-bottom: 10px;">
        <div class="forget col-sm-6">
          <a [routerLink]="['/forgot']">Forgot Password </a>
        </div>
        <div class="login col-sm-6">
          <button type="submit" class="btn btn-default btnlogin" [disabled]="!LoginForm.valid">Login</button>
        </div>
      </div>
    </div>
  </form>
  </div>

  <div class="item two"></div>
</div>
<ngx-spinner  [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">Login In...</p>
</ngx-spinner>