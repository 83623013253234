<div class="flex">
    <div class="flex-item">
      <div style="display: block;">
      <canvas baseChart width="400"
                  [datasets]="lineChartData"
                  [labels]="lineChartLabels"
                  [options]="lineChartOptions"
                  [colors]=""
                  [legend]="lineChartLegend"
                  [chartType]="lineChartType"
                  [plugins]="lineChartPlugins"></canvas>
      </div>
    </div>
  </div>