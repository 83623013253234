<app-header></app-header>
<div class="home-container">
  <div class="containerForTabsAndContent">
    <!-- Tab selection here -->
    <div class="tabs">
      Overview
    </div>
    <div class="analyticContent"></div>
    <div class="displayContainer">
            <div class="SectionsData">
                <div class="title">Lead Information</div>
                <div class="form">
                    <form action="#" method="POST">
                        <label for="FirstName"> First Name</label>
                        <input type="text" id="FirstName">
                        <label for="mobile">Mobile # or Email Address</label>
                        <input type="text" id="mobile">
                        <!-- Change Tabular to NG Materials -->
                        <div class="tabular">
                            <button type="submit">Review</button>
                            <button type="submit">Custom</button>
                        </div>
                        <div class="radioSelectors">
                            <input id="review" type="radio" name="Review" value="Review">Review
                            <input id="reminder" type="radio" name="Review" value="Reminder">Reminder
                        </div>
                        <div class="buttonFull">
                            <button id="Submit" type="submit">Send</button>
                        </div>
                        
                    </form>
                </div>
            </div>
            
                    <div class="SectionsData phoneImg">
                        <div style="position: absolute; padding: 20px; right: 240px; color: grey; background-color: white; height: 530px; width: 261px; border-radius: 0px 0 20px 20px; margin-top: 53px;">
                            Hi Jim,
                            <br>
                            <br>
                            Thank you for your support we are looking forward to hearing from you.
                        </div>
                        <div style="position: absolute; z-index: -1; right: 220px" class="phone"> 
                        </div>
                    </div>
           
            
  </div>
</div>
