import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ReusableService } from 'src/modules/reusable.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private router : Router,public reuse:ReusableService) { }

  ngOnInit() {
  }
  // goHome(a){
  //   console.log(a.index);
  //   if(a.index == '0'){
  //     this.router.navigate(['home'])
  //   }
  // }
}
