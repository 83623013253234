import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ReusableService } from "../../reusable.service";
import { ExtraServiceService } from '../extra-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  name = localStorage.name;
  designation = localStorage.role;
  logo = localStorage.image;
  locations_data = [{ id: '', title: '' }];
  locationnamedata='';
  Accountarray='';
  AccountNames:any = '';
  constructor(private router: Router, private reusable: ReusableService, private data: ExtraServiceService) { }

  ngOnInit() {
    this.getLocations();
  
  }

  getLocations() {


    this.reusable.showspinner();

    this.data.getlocationdata().subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {

          this.locations_data = result.data;


          if (!localStorage.location_id) {
            //this.setlocation(0) 
           localStorage.location_id = result.data[0].id;
            this.locationnamedata = result.data[0].title;
            localStorage.location_name = result.data[0].title;
            //localStorage.social = result.data[0].social;
             //localStorage.social = "Google,Facebook";
           
          }
          //console.log(localStorage.location_id);
          this.reusable.hidespinner();
          this.locationnamedata =  localStorage.location_name;
          this.getaccounttype();
         // this.getsocialInfo();
         // this.setheaderdata();
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  getaccounttype() {
    this.reusable.showspinner();
    this.reusable.data.role=localStorage.getItem('role');
    this.data.getaccountdetails(localStorage.location_id).subscribe(
      result => {
        if (result.success == true) {
          let results = result.data.map(a => a.type);
          localStorage.AccountType = results;

          this.reusable.data.review = results.includes('BreezeReview') ? '1' : 'NA';
          this.reusable.data.alert = results.includes('BreezeAlert') ? '1' : 'NA';
          this.reusable.data.back = results.includes('BreezeBack') ? '1' : 'NA';
          this.reusable.data.coupon = results.includes('BackCoupon') ? '1' : 'NA';
          this.reusable.data.vip = results.includes('BackVIP') ? '1' : 'NA';
          this.reusable.data.book = results.includes('BreezeBook') ? '1' : 'NA';
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  getaccounttype1() {
    this.reusable.showspinner();
    this.data.getaccountdetails(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {

          let results = result.data.map(a => a.type);

         // console.log(results);
          localStorage.AccountType = results;

          if (localStorage.AccountType.includes('BreezeReview')) { 
            // Found worldt
            this.reusable.data.review = '1';
          
          }
           if(localStorage.AccountType.includes('BreezeAlert')){
            this.reusable.data.alert = '1';
          }
           if(localStorage.AccountType.includes('BreezeBack')){
            this.reusable.data.back = '1';
          }
          location.reload();
          
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  

  }




  setlocation(ids) {
   // console.log(ids)
    localStorage.location_id = '';
    localStorage.location_id = ids;
    //this.locationnamedata = ids
    for(let newdata of this.locations_data){
      if(newdata.id == ids)
      {
        localStorage.location_id = newdata.id;
        //console.log(newdata.id)
        localStorage.location_name = newdata.title;
        this.locationnamedata = newdata.title;
        //localStorage.social = newdata.social;
        //localStorage.social = "Google,Facebook";
        //this.getsocialInfo();
        // console.log(this.locationnamedata);
        this.getaccounttype1();
       
      }
   }
  
  // this.setheaderdata()
    //console.log(this.locationnamedata);
   // console.log(this.locations_data)
  

  }




  

  // getsocialInfo() {
  //   this.reusable.showspinner();
  //   this.data.getsocialdetails().subscribe(
  //     result => {
  //       //console.log(result);
  //       if (result.success == true) {

  //         console.log(result);

  //         // let results = result.data.map(a => a.type);
  //         // localStorage.AccountType = results;

          
  //       } else {
  //         this.reusable.hidespinner();
  //         this.reusable.openSnackBar(result.error.message, "Error", "red");
  //       }
  //     },
  //     err => {
  //       this.reusable.hidespinner();
  //       this.reusable.openSnackBar(
  //         "Cannot connect to the Portal. Error Code: " + err,
  //         "Error",
  //         "red"
  //       );
  //     }
  //   );
  

  // }





  logout() {
    //alert("called")
    localStorage.clear();
    localStorage.token = '';

    localStorage.name = '';
    localStorage.location_id = '';
    //console.log(a);
    this.router.navigate(['login'])
  }

}
