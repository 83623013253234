<div class="form-group" [style.background]="color1">
 
    <form class="container1" [formGroup]="negativeform" (ngSubmit)="onSubmit()" novalidate>
      <div class="feedback">
        <div>
          <img [src]="imgURL" class="Logo" *ngIf="imgURL" />
        </div>
  
        <div style="text-align: center;" *ngIf="cancelled">
          <h5>{{notification}}</h5>
        </div>
        <div *ngIf="! cancelled">
          <div [style.background]="" class="form-group" style="color: black;text-align: center;padding: 20px;">
            Hi {{name}} , <br />{{ message }}
          </div>
  
          <mat-card [style.background]="color1">
            Select the option you want to :<br>
            <mat-radio-group aria-label="Select an option" formControlName="reason" required>
              <mat-radio-button value="4">Cancel</mat-radio-button>
              <mat-radio-button value="3" style="padding: 10px;">Reschedule</mat-radio-button>
              
              <br />
              <mat-error *ngIf="negativeform.get('reason').invalid">
                Option required
              </mat-error>
            </mat-radio-group>
          </mat-card>
        
    
          <div class="FormContainer">
           
       
          <span></span>
          <div class="example-form from-group">
    <br>
          
            <mat-form-field  style="width: 70%;padding: 10px;" class="example-full-width">
              <textarea style="height: 60px;" matInput placeholder="Reason"   formControlName="message" required ></textarea>
              <mat-error *ngIf="negativeform.get('message').invalid">
                Reason required
              </mat-error>
            </mat-form-field>
      <br><br><br>
            <button id="sendBtn" class="btn btn-default btnlogin" [style.background]="btncolor" type="submit" [disabled]="negativeform.pristine || negativeform.invalid">Send</button>
            <br>
          </div>     
        </div>
        </div>   
      </div>
    </form>
    <ngx-spinner></ngx-spinner>
  </div>