import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Router } from "@angular/router";
import { ExtraServiceService } from "../../extra-service.service";
import { ReusableService } from "../../../reusable.service";
import { AppServiceService } from "../../../app-service.service";
import { from } from "rxjs";

@Component({
  selector: "app-rating-landing-page-settings",
  templateUrl: "./rating-landing-page-settings.component.html",
  styleUrls: ["./rating-landing-page-settings.component.css"]
})
export class RatingLandingPageSettingsComponent implements OnInit {
  color1 = "#ffdddasddsd";
  color2 = "#ffdddasdaddd";
  color3 = "#f4f1f1";
  ratingid;
  alertratingid;
  ratingbackid;
  @Input() Rating_landing_message =
    " it was a pleasure assisting you. Please tell us how we did";

    @Input() Back_landing_message =
    " it was a pleasure assisting you. Please tell us how we did";

  @Input() alert_landing_message =
    "We can help reschedule your appointment. Please fill out the form below and we will get to you soon. Thank you sfsdfsfsdfsdfsdfsdf";

  @Input() alert_bottom_text =
    "Select a star rating and please wait while we redirect you to leave your written review.Thank You in Advance!";

  public imagePath;
  image: any =
    "https://image.freepik.com/free-vector/gradient-logo-template-with-abstract-shape_52683-16507.jpg";

  image2: any =
    "https://image.freepik.com/free-vector/gradient-logo-template-with-abstract-shape_52683-16507.jpg";

    image3: any =
    "https://image.freepik.com/free-vector/gradient-logo-template-with-abstract-shape_52683-16507.jpg";

  public message: string;

  selectedFile: any = null;
  submitted = false;
  ratingsettingspageform1: FormGroup;



  constructor(
    private router: Router,
    private data: ExtraServiceService,
    public reusable: ReusableService,
    public headerservice: AppServiceService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.ratingsettingspageform1 = this.fb.group({
      color1: [""],
      color2: [""],
      color3: [""],
      Rating_landing_message: [""],
      Back_landing_message: [""],
      password: [""],
      image: [""],
      image2: [""],
      image3: [""],
      id: [""],
      message: [""],
      bgcolor: [""],
      alert_landing_message: [""]
    });
    this.setupvalidation();
    if(this.reusable.data.review == '1')
    {
      this.getratingsdata();
    }
    if(this.reusable.data.alert == '1'){
      this.getalertsdata(); //getting the data for alert Landing page setting
    }
    
    if(this.reusable.data.back == '1'){
      this.getbackdata(); // getting the back data from the backend
    }
   
   
  }

  // setupvalidation(){
  //   this.ratingsettingspageform1 = new FormGroup({
  //     'color1': new FormControl(null,[Validators.required]),
  //     'color2': new FormControl(null,[Validators.required]),
  //     'Rating_landing_message': new FormControl(null,[Validators.required]),
  //     'password': new FormControl(null,[Validators.required]),
  //     'image': new FormControl(null,[Validators.required]),
  //     'id': new FormControl(null,[Validators.required]),
  //     'message': new FormControl(null,[Validators.required]),
  //     'bgcolor': new FormControl(null,[Validators.required]),
  //     'alert_landing_message': new FormControl(null,[Validators.required])
  //   });
  // }

  setupvalidation(){
    this.ratingsettingspageform1 = new FormGroup({
      'color1': new FormControl(null,[Validators.required]),
      'color2': new FormControl(null,[Validators.required]),
      'color3': new FormControl(null,[Validators.required]),
      'Rating_landing_message': new FormControl(null,[Validators.required]),
      'Back_landing_message': new FormControl(null,[Validators.required]),
      'password': new FormControl(null,[Validators.required]),
      'image': new FormControl(null,[Validators.required]),
      'image2': new FormControl(null,[Validators.required]),
      'image3': new FormControl(null,[Validators.required]),
      'id': new FormControl(null,[Validators.required]),
      'message': new FormControl(null,[Validators.required]),
      'bgcolor': new FormControl(null,[Validators.required]),
      'alert_landing_message': new FormControl(null,[Validators.required])
    });
  }

  // only number on target
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.ratingsettingspageform1.get("image").setValue(this.selectedFile);
  }

  onSubmit1(nothing) {
    this.reusable.showspinner();
    this.submitted = true;
    //console.log("called");
    if(this.ratingsettingspageform1.get("color1").value || this.ratingsettingspageform1.get("message").value){
    const fd = new FormData();
    fd.append("avatar", this.ratingsettingspageform1.get("image").value);
    fd.append("bgcolor", this.ratingsettingspageform1.get("color1").value);
    fd.append(
      "message",
      this.ratingsettingspageform1.get("Rating_landing_message").value
    );
    //  fd.append("password", this.ratingsettingspageform1.get("password").value);

    

    this.data.postratingpagesettings(fd, this.ratingid).subscribe(
      result => {
        // console.log(result);
        if (result) {
          // console.log(result);
          if (result.success == true) {
            // console.log(result);

            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "success", "green");
            this.getratingsdata();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "Error", "red");
          }
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
    }
    else{
      this.reusable.hidespinner();
      this.reusable.openSnackBar(
        "Please enter all data",
        "Error",
        "red"
      );
    }
  }

  getratingsdata() {
    this.reusable.showspinner();

    this.data.getratingsdata(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {
          this.reusable.hidespinner();
          this.Rating_landing_message = result.data.message;
          this.image = result.data.image;
          this.ratingid = result.data.id;
          this.color1 = result.data.bgcolor;
          //console.log(this.ratingid);
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }



  // use for the alert landing page settings

  getalertsdata() {
    this.reusable.showspinner();

    this.data.getalertdata(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {
          this.reusable.hidespinner();
          this.alert_landing_message = result.data.message;
          this.image2 = result.data.image;
          this.alertratingid = result.data.id;
          this.color2 = result.data.bgcolor;
         // console.log('this is alert Id ' + this.alertratingid);
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }

  onFileSelected1(event) {
    this.selectedFile = event.target.files[0];
    this.ratingsettingspageform1.get("image2").setValue(this.selectedFile);
  }

  onSubmit2(nothing) {
    this.reusable.showspinner();
    this.submitted = true;
    //console.log("called");
    const fd = new FormData();
    fd.append("avatar", this.ratingsettingspageform1.get("image2").value);
    fd.append("bgcolor", this.ratingsettingspageform1.get("color2").value);
    fd.append(
      "message",
      this.ratingsettingspageform1.get("alert_landing_message").value
    );
    //  fd.append("password", this.ratingsettingspageform1.get("password").value);

    //  if(this.ratingsettingspageform1.get("image").value || this.ratingsettingspageform1.get("color1").value || this.ratingsettingspageform1.get("message").value){

    this.data.postalertpagesettings(fd, this.alertratingid).subscribe(
      result => {
        // console.log(result);
        if (result) {
          // console.log(result);
          if (result.success == true) {
            // console.log(result);

            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "success", "green");
            this.getratingsdata();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "Error", "red");
          }
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
    // }
    // else{
    //   this.reusable.openSnackBar(
    //     "Nothing on the form has been changed ",
    //     "Error",
    //     "red"
    //   );
    // }
  }











  onFileSelected3(event) {
    this.selectedFile = event.target.files[0];
    this.ratingsettingspageform1.get("image3").setValue(this.selectedFile);
  }


  getbackdata() {
    this.reusable.showspinner();

    this.data.getbackdata(localStorage.location_id).subscribe(
      result => {
        //console.log(result);
        if (result.success == true) {
          this.reusable.hidespinner();
          this.Back_landing_message = result.data.message;
          this.image3 = result.data.image;
          this.ratingbackid = result.data.id;
          this.color3 = result.data.bgcolor;
         // console.log(this.ratingid);
        } else {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(result.error.message, "Error", "red");
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
  }



  onSubmit3(nothing) {
    this.reusable.showspinner();
    this.submitted = true;
    //console.log("called");
    if(this.ratingsettingspageform1.get("color3").value || this.ratingsettingspageform1.get("message").value){
    const fd = new FormData();
    fd.append("avatar", this.ratingsettingspageform1.get("image3").value);
    fd.append("bgcolor", this.ratingsettingspageform1.get("color3").value);
    fd.append(
      "message",
      this.ratingsettingspageform1.get("Back_landing_message").value
    );
    //  fd.append("password", this.ratingsettingspageform1.get("password").value);

    

    this.data.postbackpagesettings(fd, this.ratingbackid).subscribe(
      result => {
        // console.log(result);
        if (result) {
          // console.log(result);
          if (result.success == true) {
            // console.log(result);

            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "success", "green");
            this.getbackdata();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.message, "Error", "red");
          }
        }
      },
      err => {
        this.reusable.hidespinner();
        this.reusable.openSnackBar(
          "  Cannot connect to the Portal. Please log out and log back in",
          "Error",
          "red"
        );
      }
    );
    }
    else{
      this.reusable.hidespinner();
      this.reusable.openSnackBar(
        "Please enter all data",
        "Error",
        "red"
      );
    }
  }




}
