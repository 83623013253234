import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthServiceService as AuthGuard } from '../authenticate/auth-service.service';
//import {HomeComponent} from '../home/home.component';

const routes: Routes = [
  // { path: 'home', component: HomeComponent,canActivate: [AuthGuard] },
  // { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ReviewRoutingModule { }