<div *ngIf="active || type==='Data'" class="containerForTabsAndContent mobwidth" style="top: 30px;">
<div class="analyticContent">                      
</div>
<mat-accordion class="example-headers-align divElement">
  <mat-expansion-panel *ngIf="reuse.data.review == '1' && type==='Data'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Breeze Review
      </mat-panel-title>
     
    </mat-expansion-panel-header>
<!-- starts here -->
    <mat-tab-group [backgroundColor]="background" style="width: 100%;">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">stars</mat-icon>
          Request
        </ng-template>
       <app-viewreview></app-viewreview>
      </mat-tab>
    
      <mat-tab>
        <ng-template mat-tab-label >
          <mat-icon class="example-tab-icon">contact_support</mat-icon>
          Reputation Management
        </ng-template>
        <app-reputation></app-reputation>
      </mat-tab>
    </mat-tab-group>
    <!-- ends here -->
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="reuse.data.alert == '1' && type==='Data'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Breeze Alert
      </mat-panel-title>
     
    </mat-expansion-panel-header>
    <app-alert></app-alert>
  </mat-expansion-panel>


  <mat-expansion-panel *ngIf="reuse.data.back == '1' && type==='Back'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        BreezeBack Review
      </mat-panel-title>
     
    </mat-expansion-panel-header>
    <app-viewbreezeback></app-viewbreezeback>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="reuse.data.coupon == '1' && type==='Back'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        BreezeBack Coupon
      </mat-panel-title>
     
    </mat-expansion-panel-header>
    <app-viewbackcoupon></app-viewbackcoupon>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="reuse.data.vip == '1' && type==='Back'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        BreezeBack VIP
      </mat-panel-title>
     
    </mat-expansion-panel-header>
    <app-viewbackvip></app-viewbackvip>
  </mat-expansion-panel>

  <!-- <mat-expansion-panel *ngIf="reuse.data.back == '1'">
    <mat-expansion-panel-header>
      <mat-panel-title>
      Breeze Back
      </mat-panel-title>
     
    </mat-expansion-panel-header>
      <app-viewbreezeback></app-viewbreezeback>
  </mat-expansion-panel> -->

</mat-accordion>
</div>
<div *ngIf="!active && type==='Back'">
  <div style="margin:10vh 0px; justify-content:center;" class='InfoTip'>
    <i style="color:Orange;font-size:30px;" class='material-icons'>report_problem</i>
    <span style="font-size:20px;">You haven't registered for this service. Please <a (click)="activateBreezeBack($event)" href="#">click here</a> to activate.</span>
  </div>
  <ngx-spinner></ngx-spinner>
</div>