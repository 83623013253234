<app-header></app-header>
<div class="home-container">
  <div class="containerForTabsAndContent">
   
    <!-- Tab selection here -->
    <div class="tabs"></div>

     <div class="centerDiv">
        <!-- Scan to leave a review -->
         <qrcode [qrdata]="URL" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
     
  </div>
</div>