<div class="card">
  <div class="card-header">
   
  </div>



  <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <mat-form-field class="inputelements">
            <input matInput placeholder="Template Name" value=""   formControlName="name">
          </mat-form-field>
    
    <br>
          <mat-form-field class="inputelements">
            <textarea  matInput name="textdata" placeholder="Template Data"    formControlName="content"></textarea>  
          </mat-form-field>
          <br>


          <div class="inputelements">
            <input type="file" name="image" (change)="onFileSelected($event)">
           </div>
         

       <div class="inputelements btndiv">
        <button type="submit" class="btn btnadd" [disabled]="!reviewForm.valid">Add</button>
       </div>

     
            
         

        
    
        </div>
      </form>




  <mat-card>
    <mat-toolbar>
  
        <span>Alert Templates</span>
      </mat-toolbar>
    <mat-list *ngFor="let reviewtemp of parentdata">
      <mat-list-item>
        <div class="row col-md-6 justify-content-center">
          <div class="col-md-8">
            {{ reviewtemp.name }}
          </div>
          <div class="col-md-4 p5">
           <!-- <mat-icon *ngIf="reviewtemp.name!=='Appt. Confirmation' "
              aria-hidden="false"
              aria-label="Example remove icon"
              (click)="delete(reviewtemp)"
              style="font-size:20px;color:darkcyan;cursor: pointer;margin-right: 20%;"
              >delete</mat-icon
            >-->
           
            <mat-icon
              aria-hidden="false"
              aria-label="Example edit icon"
              (click)="openDialog(reviewtemp)"
              style="font-size:20px;color:darkcyan;cursor: pointer;"
              >edit</mat-icon
            >
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
