import React from 'react'

import Appointment from '../appointmentComponent/appointment'

const PendingAppointments = (props) => {

    const sortBasedOnTime = (obj1,obj2) => {
        let startM = obj1.start_time.split(' ')[1];
        let startTime = Number(obj1.start_time.split(' ')[0].split(':').join(''));
        let endM = obj2.start_time.split(' ')[1];
        let endTime = Number(obj2.start_time.split(' ')[0].split(':').join(''));
        if(startM === endM){
            startTime = startTime < 1200 ? startTime+1200 : startTime;
            endTime = endTime < 1200 ? endTime+1200 : endTime;
            return startTime > endTime ? 1 : -1
        }else{
            return startM > endM ? 1 : -1
        }
    }

    const sortBasedOnDate = (obj1,obj2) => {
        let date1 = new Date(obj1.date), date2 = new Date(obj2.date)
        if(date1.getTime() === date2.getTime())
            return sortBasedOnTime(obj1,obj2);
        else
            return date1 > date2 ? 1 : -1
    }

    return(
        <>
            <div className='ActionDiv'>
                <h4>Cancellation Requests</h4>
            </div>
            <div style={{paddingTop:'30px'}} className='Appointments'>
                {
                   props.data.length !== 0 ? 
                   props.data.sort(sortBasedOnDate).map(appointment => (
                    <Appointment 
                    key={appointment.id}
                    id={appointment.id} 
                    date={appointment.date}
                    name={appointment.name}
                    number={appointment.number} 
                    email={appointment.email}
                    slot={appointment.start_time+' - '+appointment.end_time}
                    service={appointment.service_name}
                    staff={appointment.staff_name}
                    paypal={props.hasPaypal}
                    currency={props.currency}
                    paid={appointment.paid_amount}
                    refund={appointment.refund_amount}
                    actionRequired= {props.isAdmin}
                    isAdmin={props.isAdmin}
                    doneName='Approve'
                    cancelName='Reject'
                    doneFn = {() => props.changeStatus(appointment.id,'Cancelled')}
                    cancelFn = {() => props.changeStatus(appointment.id,'Confirmed')}
                    refundFn={props.refundFn}
                    refresh = {props.refreshFn}
                     />
                   )) : <h6>No Pending Cancellation Requests!</h6>
                }
            </div>
        </>
    )
}

export default PendingAppointments;