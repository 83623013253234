import React from 'react'

import './loader.css'

const Loader = () => (
    <>
    <div className="Loader">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>
    <div className='LoaderBackDrop'></div>
    </>
)

export default Loader;