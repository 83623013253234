import { Component, OnInit, Input } from '@angular/core';
import {ReusableService} from '../reusable.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @Input()selectedIndex: number | null;
  selected = 1;
  constructor(public reuse:ReusableService) { }

  ngOnInit() {
  }

}
