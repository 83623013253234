import React from 'react'
import {cacheHeaders,basePath} from '../config'

import './appointment.css'
import Appointments from './appointments'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'; 

interface propsCompState {
    [key: string]: any
}

const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }

const statusArr=['Confirmed','Completed','Cancelled'];

class AppointmentComponent extends React.Component<propsCompState,propsCompState>{

    state={
        activeTab : 0,
        searchTerm: '',
        dateRange: [new Date(new Date().setDate(new Date().getDate() - 14)),new Date(new Date().setDate(new Date().getDate() + 14))],
        iframeActive: false,
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    tabNavigator = (index:number) => {
        this.setState({activeTab:index});
        document.getElementById('tabContent'+index).scrollIntoView();
    }

    updateSearchTerm = (ev) => {
        this.setState({searchTerm: ev.target.value});
    }

    dateRangeHandler = (range) => {
        this.setState({dateRange:range});
    }

    addAppointmentHandler =() => {
        this.setState((prevstate)=> (
            {iframeActive: !prevstate.iframeActive}
        ))
    }

    componentDidMount(){
        let collElems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collElems);
    }

    render(){

        return(
            <>
                <div className='ActionDiv'>
                    <h4>Appointments Dashboard</h4>
                    <button onClick={this.addAppointmentHandler} className='btn-flat waves-effect waves-light ActionBtn' type='button' >
                        <i style={{marginRight:'2px'}} className="material-icons left">add</i>
                        Add Appointment
                    </button> 
                </div>
                <div className='FilterContainer'>
                    <ul className="collapsible">
                        <li>
                            <div className="collapsible-header"><i className="material-icons">filter_list</i>Filters</div>
                            <div className="collapsible-body">
                                <DateRangePicker
                                    className='card-panel DateRangePicker'
                                    onChange={this.dateRangeHandler}
                                    value={this.state.dateRange}
                                />
                                <div className="input-field col s12 FilterInput">
                                    <i className="material-icons">search</i>
                                    <input placeholder='search by client/staff/service' id='searchInput' type='text' value={this.state.searchTerm} onChange={this.updateSearchTerm} />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='TabsContainer'>
                    <div onClick={()=>this.tabNavigator(0)} className={'TabHeader '+ (this.state.activeTab === 0 ? 'TabActive':'')}>Confirmed</div>
                    <div onClick={()=>this.tabNavigator(1)} className={'TabHeader '+ (this.state.activeTab === 1 ? 'TabActive':'')}>Completed</div>
                    <div onClick={()=>this.tabNavigator(2)} className={'TabHeader '+ (this.state.activeTab === 2 ? 'TabActive':'')}>Cancelled</div>
                </div>
                <div className='TabsContent'>
                    {
                        statusArr.map((status, index) => (
                            <Appointments
                                key={status}
                                isAdmin={this.props.role==='Admin'}
                                userId={this.props.userId}
                                startDate={this.state.dateRange[0].toLocaleDateString('en-US',dateOptions)}
                                endDate={this.state.dateRange[1].toLocaleDateString('en-US',dateOptions)}
                                searchKey={this.state.searchTerm.toLowerCase()}
                                id={'tabContent'+index}
                                status = {status}
                                statusTracker = {this.props.statusValue}
                                track = {this.props.trackStatus}
                                hasPaypal={this.props.hasPaypal}
                                currency={this.props.currency}
                                refund={this.props.refundFn}
                            />
                        ))
                    }
                </div>
                {
                    this.state.iframeActive &&
                <div className='ClientApp'>
                    <i onClick={this.addAppointmentHandler} className="material-icons">close</i>
                    <iframe id='iframeClient' src={"https://breezebook.app/"+localStorage.compName} title='Book an Appointment' width="100%" height="100%"></iframe>
                    <div onClick={this.addAppointmentHandler} className='BackDrop'></div>
                </div>
                }
                
            </>
        )
    }
}

export default AppointmentComponent;