import { Component, OnInit, Input } from "@angular/core";
import { Form, FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomValidators } from "ngx-custom-validators"; // this is used for the custom errors in the application
import { ReviewServiceService } from "../review-service.service";
import { ReusableService } from "../../reusable.service";
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
@Component({
  selector: "app-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.css"]
})
export class RequestComponent implements OnInit {
  
  
  templatedata;
  temp_data_to_account_id;
  alertindex= 0;
  temp_data_to_display = { id: "", templatename: "", templatecontent: "", templateimg: "",image:"",content:"" };
  message = "Choose your template";
  name: string = "xyz";
  username = "";
  imageTemplate = "";
  temp_id;
  isShown: boolean = false ; // hidden by default
  @Input()selectedIndex: 0 | null;

  requestForm: FormGroup; //validation

  constructor(
    private data: ReviewServiceService,
    public reusable: ReusableService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.setupvalidation();
    this.getTemplateData();
    this.temp_data_to_display.templateimg = "assets/your-logo-here.png";
    this.temp_data_to_display.templatecontent = "Your Template data here";
    if(this.reusable.data.alert === '1' && this.reusable.data.review !== '1'){
      this.isShown = true;
      this.alertindex = 1;
    }    
  }

  setupvalidation(){
    this.requestForm = new FormGroup({
      'name': new FormControl(null,[Validators.required]),
      'number': new FormControl(null,[]),
      'email': new FormControl(null,[]),
      'date': new FormControl(null,[Validators.required]),
      'time': new FormControl(null,[Validators.required])
    });
  }

  keyPress(event: any) {
    const pattern = /[]/;
  
    let inputChar = String.fromCharCode(event.charCode);
    // console.log(inputChar, e.charCode);
       if (!pattern.test(inputChar)) {
       // invalid character, prevent input
           event.preventDefault();
      }
 }


  getTemplateData() {
    this.reusable.showspinner();
// console.log(localStorage.location_id)
    if(localStorage.location_id == '')
    {
      window.location.reload()
    }
    else{
      this.data.gettemplatedata(localStorage.location_id).subscribe(
        result => {
          //console.log(result);
          if (result.success == true) {
            this.templatedata = result.data;
            //console.log(this.templatedata);
            this.reusable.hidespinner();
          } else {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(result.error.message, "Error", "red");
          }
        },
        err => {
          this.reusable.hidespinner();
          this.reusable.openSnackBar(
            "  Cannot connect to the Portal. Please log out and log back in",
            "Error",
            "red"
          );
        }
      );
    } 
  }

  onSubmit() {
    if(this.requestForm.get("number").value || this.requestForm.get("email").value)
    {
    this.reusable.showspinner();
    const fd = new FormData();
    fd.append("first_name", this.requestForm.get("name").value);
    this.requestForm.get("number").value && fd.append("number", this.requestForm.get("number").value);
    this.requestForm.get("email").value && fd.append("email", this.requestForm.get("email").value);
    fd.append("appointment_date",  this.datepipe.transform(this.requestForm.get("date").value, 'MM/dd/yyyy'));
    fd.append("appointment_time", this.datepipe.transform(this.requestForm.get("time").value, 'h:mm a'));
    fd.append("template_id",  this.temp_id);
    if(this.alertindex == 1){
          if(this.requestForm.get("time").value && this.requestForm.get("date").value &&  this.requestForm.get("name").value && this.temp_id){

            this.data.sendviewlist(fd).subscribe(
              result => {
                // console.log(result);
                if (result.success == true) {                  
                  this.reusable.hidespinner();
                  this.reusable.openSnackBar(
                    "Request has been sent",
                    "",
                    "green"
                  );                 
                  this.data.updateListFnAlert(localStorage.location_id);                 
                } else {
                  this.reusable.hidespinner();
                  this.reusable.openSnackBar(result.error.message, "Error", "red");
                }
              },
              err => {
                this.reusable.hidespinner();
                this.reusable.openSnackBar(
                  "  Cannot connect to the Portal. Please log out and log back in",
                  "Error",
                  "red"
                );
              }
            );
          }
          else
          {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(
              "Complete all required fields",
              "Error",
              "red"
            );
          }
    }
    else
    {
          if(this.alertindex == 0 && this.requestForm.get("name").value && this.temp_id){

            this.data.sendviewlist(fd).subscribe(
              result => {
                //console.log(result);
                if (result.success == true) {
                  
                  this.reusable.hidespinner();
                  this.reusable.openSnackBar(
                    "Request has been sent",
                    "",
                    "green"
                  );

              //  console.log("here");
                

                  this.data.updateListFn(localStorage.location_id);
                  
                } else {
                  this.reusable.hidespinner();
                  this.reusable.openSnackBar(result.message, "Error", "red");
                }
              },
              err => {
                this.reusable.hidespinner();
                this.reusable.openSnackBar(
                  "  Cannot connect to the Portal. Please log out and log back in",
                  "Error",
                  "red"
                );
              }
            );

          }
          else
          {
            this.reusable.hidespinner();
            this.reusable.openSnackBar(
              "Complete all required fields",
              "Error",
              "red"
            );
          }
    }
  }else{
    this.reusable.hidespinner();
    this.reusable.openSnackBar(
      "Number and/or Email is required",
      "Error",
      "red"
    );
  }
  }



  findTempelatedata(id) {
    return this.templatedata.find(x => x.id === id);
  }

  findTempelateAccount(id) {
    return this.templatedata.find(x => x.id === id);
  }


  resetTemplate() {
    // console.log("called");
    this.temp_data_to_display = null;
  }

  getTemplateDataReview(getid) { // geting  the template data to send
    this.temp_id = getid;
    this.temp_data_to_display = this.findTempelatedata(getid);
    this.temp_data_to_account_id = this.findTempelateAccount(getid);
    this.temp_data_to_account_id = this.temp_data_to_account_id.account_id;
    // console.log(this.temp_data_to_account_id);
  }

  tabChange(ev){
    if(this.reusable.data.review === '1' && this.reusable.data.alert === '1'){
      this.alertindex  = ev.index;
      this.isShown = ev.index === 1;
      this.temp_id = 0;
    }
  }
}
