import { Injectable } from '@angular/core';
import { MatSnackBar,MatSnackBarConfig } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class ReusableService {
  
  location_id = localStorage.location_id;

  graphdata;
  data = {back:'',review:'',alert:'',role:'',coupon:'',vip:'',book:''};

  constructor( private _snackBar: MatSnackBar,private spinner: NgxSpinnerService) { 
    this.setroles(this.data);
    //console.log(this.data)
    //this.checkAccountType();
  }

  sendActivationMail = (account) => {
    this.showspinner();
    fetch('https://apil.breezereview.com/api/contact?location_title='+localStorage.location_name+'&account_type='+account,{
        headers:{
            'Authorization': 'Bearer '+ localStorage.token,
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
    .then(result => {
        if(result.success){
          this.openSnackBar("Activation Mail received. You will be contacted shortly!","","green");
        }else{
          this.openSnackBar(result.message,"Error","red");
        }
        this.hidespinner();
    })
    .catch(err => {
        this.hidespinner();
        this.openSnackBar("An error occurred and the action could not be completed","Error","red");
    })
  }
  

  openSnackBar(message: string, action: string,color) {
    let config = new MatSnackBarConfig();
    config.panelClass = [color];
    config.duration = 5000;
    config.verticalPosition = 'top';
   return this._snackBar.open(message, action, config);
  }
  showspinner(){
    this.spinner.show();
  }
  hidespinner(){
    this.spinner.hide();
  }

  setroles(data){
    // localStorage.isback = data.back;
    // localStorage.isreview = data.review;
    // localStorage.isalert = data.alert;
    // localStorage.isrole = data.role;
  }

}
