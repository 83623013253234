<br />
<mat-toolbar color="" class="container">
  <span>My Profile</span>
</mat-toolbar>
<mat-card>
  <form [formGroup]="profileform1" (ngSubmit)="onSubmit1(profileform1)">
    <div class="form-group">
      <mat-form-field class="inputelements">
        <input matInput placeholder="Email" value="{{ email }}" disabled />
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field class="inputelements">
        <input matInput placeholder="First Name" value="{{ fname }}" formControlName = "first_name" />
      </mat-form-field>

      <mat-form-field class="inputelements">
        <input matInput placeholder="Last Name" value="{{ lname }}" formControlName = "last_name"/>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field class="inputelements">
        <input matInput placeholder="Password" value="" formControlName = "password"/>
      </mat-form-field>
    </div>

    <div class="form-group">
      <div class="inputelementssmall">
        <input type="file" name="image" (change)="onFileSelected($event)" />
      </div>
    </div>
    <button class="btn btn-primary" type="submit" [disabled]="!profileform1.valid">Update</button>
  </form>

  
</mat-card>
