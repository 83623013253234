import React from 'react';

import {cacheHeaders,basePath} from '../config'
import M from 'materialize-css';
import Loader from '../loader/loader'

const initialFormData = {
    id : 0,
    first_name : '',
    last_name : '',
    email: '',
    password: '',
}

interface compState {
    [key: string]: any
}

class StaffComponent extends React.PureComponent<{},compState>{

    state={
        isLoading: true,
        staffs : [],
        formOpen : false,
        formType: 'Add',
        formData : initialFormData,
        delElement: {
            id : 0,
            first_name: ''
        }
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

   formOpenHandler = () => {
        this.setState((prevState) => {
            return {formOpen : !prevState.formOpen,formType: 'Add', formData : initialFormData}
        });
        (document.getElementById('staffForm') as HTMLFormElement).reset();
    }

    inputChangeHandler = (event) => {
        let formData = {...this.state.formData};
        formData[event.target.name] = event.target.value;
        this.setState({
            formData : formData
        })
    }

    editHandler = (staff) => {
        (document.getElementById('staffForm') as HTMLFormElement).reset();
        this.setState((prevState) => {
            return {formOpen : !prevState.formOpen,formType: 'Edit', formData : {...staff,password:''}}
        });
    }

    deleteHandler = () => {
        this.setState({isLoading: true});
        fetch(basePath+'staff/'+this.state.delElement.id,{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'delete'
        })
        .then(res => res.ok ? res.json() : Promise.reject())
        .then(result => {
            this.toaster(result.success,result.message);
            result.success && this.getStaffList();
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    getStaffList = () => {
        this.setState({isLoading: true});
        fetch(basePath+'staff',{
            headers:{
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            if(result.success){
                this.setState({staffs: result.data});
            }else{
                this.toaster(false, result.message);
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    submitHandler = (event) => {
        this.setState({isLoading: true});
        event.preventDefault();
        let url = basePath + 'staff';
        let postData ={}
        if(this.state.formData.id !== 0){
            url = url + '/' + this.state.formData.id;
            postData = {...this.state.formData, _method : 'put'}
        }else{
            postData = {...this.state.formData}
        }
        fetch(url,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.token,
                ...cacheHeaders,
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(result => {
            this.toaster(result.success, result.message);
            if(result.success){
                this.formOpenHandler();
                this.getStaffList();
            }
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false, 'An error occurred and the action could not be completed!');
            this.setState({isLoading: false});
        })
    }

    componentDidMount(){
        M.AutoInit();
        this.getStaffList();
    }

    render(){
        return(
            <>
                <div className='ActionDiv'>
                    <h4>Available Staff</h4>
                    <button onClick={this.formOpenHandler} className='btn-flat waves-effect waves-light ActionBtn' type='button' >
                        <i style={{marginRight:'5px'}} className="material-icons left">add</i>
                        Add Staff
                    </button>
                </div>
                <div className='InfoTip'>
                    <i style={{color:'#4CAF50'}} className={"material-icons"}>info</i>
                    <span>Add or Modify the details of the staff</span>
                </div>
                <br/>
                <div className='EntityContainer'>
                {
                    this.state.staffs.length === 0 ? <h5>No Staff available at the moment</h5> : 
                    this.state.staffs.map(staff => (
                        <div key={staff.id} className='Staff'>
                            <div>
                                <h6 style={{color:'var(--secondary-font-color)',fontWeight:'bold'}}>{staff.first_name + ' ' + staff.last_name}</h6>
                                <p><b>e-mail: </b>{staff.email}</p>
                            </div>
                            <div className='StaffAction'>
                                <button style={{color:'#4CAF50'}} className='btn ActionBtn' onClick={() => this.editHandler({...staff})} type='button' >Edit</button>
                                <button style={{color:'#F44336'}} className='btn ActionBtn modal-trigger' data-target="delete_modal" onClick={() => this.setState({delElement:{...staff}})} type='button' >Delete</button>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className={'FormContainer card-panel' + (this.state.formOpen ?' FormOpen' : '')}>
                    <div className='FormHeader'>
                        <h5 style={{color:'var(--secondary-font-color)'}}>{this.state.formType + ' Staff'}</h5>
                        <i style={{cursor:'pointer'}} onClick={this.formOpenHandler} className="material-icons">close</i>
                    </div>
                    <form onSubmit={this.submitHandler} id='staffForm' style={{width:'100%',height:'80%',overflowY:'auto',overflowX:'hidden'}}>
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="first_name" name='first_name' value={this.state.formData.first_name} onChange={this.inputChangeHandler} type="text" required autoComplete='off' className="validate" />
                                <label className={this.state.formData.first_name !== '' ? 'active' : ''} htmlFor="first_name">First Name</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="last_name" name='last_name' value={this.state.formData.last_name} onChange={this.inputChangeHandler} type="text" required autoComplete='off' className="validate" />
                                <label className={this.state.formData.last_name !== '' ? 'active' : ''} htmlFor="last_name">Last Name</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="email" name='email' value={this.state.formData.email} onChange={this.inputChangeHandler} type="email" required autoComplete='off' className="validate" />
                                <label className={this.state.formData.email !== '' ? 'active' : ''} htmlFor="email">Email</label>
                            </div>
                            {this.state.formType === 'Add' ?
                                <div className="input-field col s12">
                                    <input id="password" name='password' value={this.state.formData.password} onChange={this.inputChangeHandler} type="password" required autoComplete='off' className="validate" />
                                    <label className={this.state.formData.password !== '' ? 'active' : ''} htmlFor="password">Password</label>
                                </div> : 
                                <div className="input-field col s12">
                                    <input id="password" name='password' value={this.state.formData.password} onChange={this.inputChangeHandler} type="password" autoComplete='off' className="validate" />
                                    <label className={this.state.formData.password !== '' ? 'active' : ''} htmlFor="password">Password</label>
                                </div>
                            }

                        </div>
                        <div className='FormAction'>
                            <button style={{color:'#4CAF50'}} className='btn ActionBtn' type='submit' >Save</button>
                            <button style={{color:'#F44336'}} onClick={this.formOpenHandler} className='btn ActionBtn' type='button' >Cancel</button>
                        </div>
                    </form>
                </div>
                {this.state.formOpen && <div onClick={this.formOpenHandler} className='BackDrop'></div>}
                <div id="delete_modal" className="modal">
                    <div className="modal-content">
                    <h4 style={{color:'var(--secondary-font-color)'}}>Delete Staff</h4>
                    <h6>Are you sure you want to remove this staff?<br/><br/>
                        <b>{this.state.delElement.first_name}</b>
                    </h6>
                        <div className="modal-footer">
                            <button style={{color:'#4CAF50'}} className="btn ActionBtn modal-close" onClick={this.deleteHandler}>Yes</button>
                            <button style={{color:'#F44336'}} className="btn ActionBtn modal-close">No</button>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <Loader />}
            </>
        )
    }
    
}

export default StaffComponent;